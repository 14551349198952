import IconButton from '@mui/material/IconButton'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

export type DeleteItem = {
    id: string
    name: string
}

export type ComponentProps<T extends DeleteItem> = {
    item: T
    onDelete: (id: string | undefined) => void
}

const DeleteButton = <T extends DeleteItem>({
    onDelete,
    item,
}: ComponentProps<T>) => {
    const [open, setOpen] = React.useState(false)

    const deleteModal = React.useMemo(() => {
        return (
            <Grid
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: 2,
                    }}
                >
                    <h1>Are you sure you want to delete {item.name}?</h1>
                    <Grid display="flex" gap={3}>
                        <Button
                            color={'error'}
                            variant="contained"
                            onClick={() => onDelete(item.id)}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        )
    }, [onDelete, item])

    return (
        <>
            <IconButton aria-label="info" onClick={() => setOpen(true)}>
                <DeleteIcon />
            </IconButton>
            <Modal open={open} onClose={() => console.log('closed')}>
                {deleteModal}
            </Modal>
        </>
    )
}

export default React.memo(DeleteButton)
