import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import {
    useAuthenticateGoogleLazyQuery,
    useAuthenticateLazyQuery,
} from '../../generated/graphql'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { saveSession } from '../../lib/sessionStorage'
import { GoogleLogin } from '@react-oauth/google'

const Login = () => {
    const navigate = useNavigate()

    const [authenticate, { loading: userPassLoading, error: userPassError }] =
        useAuthenticateLazyQuery({
            fetchPolicy: 'no-cache',
        })

    const [authenticateGoogle, { loading: googleLoading, error: googleError }] =
        useAuthenticateGoogleLazyQuery({
            fetchPolicy: 'no-cache',
        })

    const error = React.useMemo(() => {
        return userPassError || googleError
    }, [userPassError, googleError])

    const loading = React.useMemo(() => {
        return userPassLoading || googleLoading
    }, [userPassLoading, googleLoading])

    const googleLogin = React.useCallback(
        async (credentialResponse: any) => {
            const { credential } = credentialResponse

            const response = await authenticateGoogle({
                variables: {
                    data: {
                        idToken: credential,
                    },
                },
            })

            saveSession(response.data?.authenticateGoogle?.token || '')
            navigate('/')
        },
        [authenticateGoogle, navigate]
    )

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const data = new FormData(event.currentTarget)

            const response = await authenticate({
                variables: {
                    data: {
                        email: data.get('email') as string,
                        password: data.get('password') as string,
                    },
                },
            })

            saveSession(response.data?.authenticate?.token || '')
            navigate('/')
        },
        [authenticate, navigate]
    )

    return (
        <Grid
            container
            component="main"
            sx={{ height: '100vh' }}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <CssBaseline />

            <Grid item component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                    }}
                >   
                    <Box>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>

                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                error={!!error}
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                sx={{ marginBottom: '2rem' }}
                                margin="normal"
                                required
                                fullWidth
                                error={!!error}
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign In
                                    </Button>

                                    <Typography>
                                        OR login with google
                                    </Typography>

                                    <GoogleLogin
                                        onSuccess={googleLogin}
                                        onError={() => {
                                            console.log('Login Failed')
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default React.memo(Login)
