import React from 'react'

import {
    CreateSupplierMutation,
    Supplier,
    useCreateSupplierMutation,
} from '../../../generated/graphql'

import Loading from '../../loading'
import SupplierForm from '../supplierForm/SupplierForm'
import { useNavigate } from 'react-router-dom'
import { omit } from 'lodash'
import { addToQuery } from '../../../apollo/resolvers'

const NewSupplier = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreateSupplierMutation()

    const submit = React.useCallback(
        (supplier: Supplier) => {
            create({
                variables: {
                    data: {
                        ...omit(supplier, 'defaultCategory'),
                        defaultCategoryId: supplier.defaultCategory?.id,
                    },
                },
                onCompleted(data: CreateSupplierMutation) {
                    addToQuery(data.createSupplier, 'suppliers')
                    navigate(-1)
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <SupplierForm
            supplier={{
                name: '',
                phone: '',
                email: '',
                nif: '',
                website: '',
                defaultCategory: undefined,
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewSupplier)
