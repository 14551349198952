import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Person,
    useGetTherapistQuery,
    useUpdateTherapistMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import TherapistForm from '../therapistForm/TherapistForm'

const UpdateTherapist = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetTherapist } = useGetTherapistQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateTherapist, { loading: loadingUpdate }] =
        useUpdateTherapistMutation()

    const submit = React.useCallback(
        (person: Person) => {
            updateTherapist({
                variables: {
                    data: {
                        id,
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                        conditions: person?.therapist?.conditions,
                        licenseCode: person?.therapist?.licenseCode,
                        irpf: person?.therapist?.irpf,
                        defaultCategoryId:
                            person?.therapist?.defaultCategory?.id,
                        defaultPrice: person?.therapist?.defaultPrice,
                        calendarId: person?.therapist?.calendarId,
                        disabled: person?.therapist?.disabled,
                    },
                },
                onCompleted() {
                    navigate('/people/therapists')
                },
                refetchQueries: ['getTherapists', 'getPeople'],
            })
        },
        [navigate, id, updateTherapist]
    )

    const therapistForm = React.useMemo(() => {
        return data?.therapist ? (
            <TherapistForm therapist={data?.therapist} submit={submit} />
        ) : null
    }, [data?.therapist, submit])

    return loadingGetTherapist || loadingUpdate ? <Loading /> : therapistForm
}

export default React.memo(UpdateTherapist)
