import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    PaymentMethod,
    useGetPaymentMethodQuery,
    useUpdatePaymentMethodMutation,
} from '../../../generated/graphql'
import PaymentMethodForm from '../paymentMethodForm'
import Loading from '../../loading'

const UpdatePaymentMethod = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetPaymentMethod } = useGetPaymentMethodQuery(
        {
            variables: {
                data: {
                    id,
                },
            },
        }
    )

    const [updatePaymentMethod, { loading: loadingUpdate }] =
        useUpdatePaymentMethodMutation()

    const submit = React.useCallback(
        (paymentMethod: PaymentMethod) => {
            updatePaymentMethod({
                variables: {
                    data: {
                        id: paymentMethod.id,
                        name: paymentMethod.name,
                    },
                },
                onCompleted() {
                    navigate('/payment-methods')
                },
                refetchQueries: ['getPaymentMethods'],
            })
        },
        [navigate, updatePaymentMethod]
    )

    const accountForm = React.useMemo(() => {
        return data?.paymentMethod ? (
            <PaymentMethodForm
                paymentMethod={data?.paymentMethod}
                submit={submit}
            />
        ) : null
    }, [data?.paymentMethod, submit])

    return loadingGetPaymentMethod || loadingUpdate ? <Loading /> : accountForm
}

export default React.memo(UpdatePaymentMethod)
