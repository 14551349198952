import Grid from '@mui/material/Grid'
import React from 'react'
import TherapistTable from './therapistTable'

const Therapists = () => {
    return (
        <Grid>
            <TherapistTable />
        </Grid>
    )
}

export default React.memo(Therapists)
