import { gql } from '@apollo/client'

export const AUTHENTICATE = gql`
    query authenticate($data: AuthenticateParameters) {
        authenticate(data: $data) {
            email
            name
            id
            token
        }
    }
`

export const AUTHENTICATE_GOOGLE = gql`
    query authenticateGoogle($data: GoogleAuth) {
        authenticateGoogle(data: $data) {
            email
            name
            id
            token
        }
    }
`

export const AUTHORISED = gql`
    query authorized {
        authorized {
            email
            name
            id
        }
    }
`
