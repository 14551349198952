import React from 'react'
import {
    useDeletePaymentMethodMutation,
    useGetPaymentMethodsQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import DeleteButton from '../deleteButton'
import { DeleteItem } from '../deleteButton/DeleteButton'

const PaymentMethods = () => {
    const navigate = useNavigate()
    const { data: paymentMethodsData, loading } = useGetPaymentMethodsQuery()

    const [deleting, setDeleteting] = React.useState<string[]>([])
    const [deletePaymentMethod, { loading: loadingDelete }] =
        useDeletePaymentMethodMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deletePaymentMethod({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getPaymentMethods'],
            })
        },
        [deletePaymentMethod, deleting, setDeleteting]
    )
    const paymentMethods = React.useMemo(
        () => paymentMethodsData?.paymentMethods || [],
        [paymentMethodsData]
    )

    return loading ? (
        <Loading />
    ) : (
        <List>
            {paymentMethods.map((paymentMethod) => (
                <ListItem
                    key={paymentMethod.id}
                    divider
                    sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                    <ListItemText primary={paymentMethod.name} />
                    {loadingDelete &&
                    deleting.includes(paymentMethod.id || '') ? (
                        <Loading />
                    ) : (
                        <ListItemSecondaryAction>
                            <DeleteButton
                                onDelete={handleDelete}
                                item={paymentMethod as DeleteItem}
                            />
                            <IconButton
                                aria-label="info"
                                onClick={() =>
                                    navigate(`update/${paymentMethod.id}`)
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))}
        </List>
    )
}

export default React.memo(PaymentMethods)
