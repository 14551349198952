import Grid from '@mui/material/Grid'
import React from 'react'

type ComponentProps = {
    children: React.ReactNode
}

const PublicScreen = ({ children }: ComponentProps) => {
    return <Grid>{children}</Grid>
}

export default React.memo(PublicScreen)
