import { getCache } from './client'

export const addToQuery = (obj: any, query: string) => {
    const cache = getCache()

    cache.modify({
        fields: {
            [query]: (existing: any, { toReference }) => {
                return [...existing, toReference(obj)]
            },
        },
    })
}

export const evictQuery = (query: string) => {
    const cache = getCache()

    cache.evict({ fieldName: query })
    cache.gc()
}
