import React from 'react'

import {
    CreatePaymentMethodMutation,
    PaymentMethod,
    useCreatePaymentMethodMutation,
} from '../../../generated/graphql'

import Loading from '../../loading'
import PaymentMethodForm from '../paymentMethodForm/PaymentMethodForm'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../apollo/resolvers'

const NewPaymentMethod = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreatePaymentMethodMutation()

    const submit = React.useCallback(
        (paymentMethod: PaymentMethod) => {
            create({
                variables: {
                    data: paymentMethod,
                },
                onCompleted(data: CreatePaymentMethodMutation) {
                    addToQuery(data.createPaymentMethod, 'paymentMethods')
                    navigate(-1)
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <PaymentMethodForm
            paymentMethod={{
                name: '',
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewPaymentMethod)
