import {
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import React from 'react'
import logo from '../../logos/logo512.png'
import { useParams } from 'react-router-dom'
import { useGetTransactionQuery } from '../../generated/graphql'
import Loading from '../loading'
import dayjs from '../../lib/dayjs'
const Invoice = () => {
    const { id } = useParams()

    const { data: transactionData, loading: transactionLoading } =
        useGetTransactionQuery({
            variables: {
                data: { id },
            },
        })

    const order = React.useMemo(() => {
        if (transactionData?.transaction) {
            return transactionData?.transaction?.order
        }
    }, [transactionData?.transaction])

    const customer = React.useMemo(() => {
        return order?.customer
    }, [order?.customer])

    const discount = React.useMemo(() => {
        const sum =
            order?.rows?.reduce((acc, row) => {
                return acc + (row.amount || 0) * (row.article?.price || 0)
            }, 0) || 0

        if (sum === transactionData?.transaction?.amount) return 0
        const disc =
            sum - (transactionData?.transaction?.amount || 0) / (sum || 1)

        return disc
    }, [order?.rows, transactionData?.transaction?.amount])

    return transactionLoading ? (
        <Loading />
    ) : (
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <Paper sx={{ height: '27.9cm', width: '21cm' }}>
                <Grid
                    id="header"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: '1cm',
                        paddingLeft: '1cm',
                        paddingRight: '1cm',
                    }}
                >
                    <Grid>
                        <h1>Ohana Salut Integrativa</h1>
                        <h3>
                            Transaction ID: {transactionData?.transaction?.id}
                        </h3>
                        <h3>Date: {dayjs(order?.date).format('DD/MM/YYYY')}</h3>
                    </Grid>
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            height: '3cm',
                        }}
                    />
                </Grid>
                <Divider />
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        lineHeight: 0.5,
                    }}
                >
                    <Grid id="companyData">
                        <h3>Order from</h3>
                        <p>Ohana salut integrativa</p>
                        <p>Plaça Assumpció nº 6 Baixos, </p>
                        <p>17005 Girona (España)</p>
                        <p>info@ohanasalut.com</p>
                    </Grid>

                    <Grid id="customerData">
                        <h3>Order to</h3>
                        <p>{customer?.fullName}</p>
                        <p>{customer?.address}</p>
                        <p>{customer?.phone}</p>
                        <p>{customer?.email}</p>
                    </Grid>
                </Grid>
                <Divider />
                <Grid id="ArticlesData" sx={{ padding: 3, paddingBottom: 0 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Article</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order?.rows?.map((row) => {
                                const id =
                                    row.article?.id ||
                                    row.session?.therapist?.id
                                const name =
                                    row.article?.name ||
                                    `${row.session?.therapist?.fullName} - ${row.category?.name}`
                                const amount = `${row.amount || 0}${
                                    row.session ? 'h' : 'u'
                                }`
                                const price =
                                    row.article?.price ||
                                    row.session?.payedPrice
                                const total = (row.amount || 0) * (price || 0)
                                return (
                                    <TableRow key={id}>
                                        <TableCell align="left">
                                            {name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {amount}
                                        </TableCell>
                                        <TableCell align="right">
                                            {price}€
                                        </TableCell>
                                        <TableCell align="right">
                                            {total}€
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {discount > 0 ? (
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">
                                        <b>Discount:</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{discount}%</b>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right">
                                    <b>IVA:</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>0€</b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right">
                                    <b>Total:</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b>
                                        {transactionData?.transaction?.amount}€
                                    </b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid id="footerData"></Grid>
            </Paper>
        </Grid>
    )
}
export default React.memo(Invoice)
