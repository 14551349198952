import React from 'react'
import {
    TransactionSearch,
    TransactionSearchFilters,
    TransactionType,
    useSearchTransactionsQuery,
} from '../../generated/graphql'
import { transactionOrderCategories } from '../../lib/transaction'
import Paginator from '../paginator'
import TransactionSearchBar from '../searchBar/transactionSerachBar'
import { Grid, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate } from 'react-router-dom'
import dayjs from '../../lib/dayjs'
import CustomDataGrid from '../customDataGrid'
const Transactions = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = React.useState<TransactionSearch>({
        limit: 25,
        offset: 0,
        filters: undefined,
    })

    const { data: transactionsData, loading: loadingTransactions } =
        useSearchTransactionsQuery({
            variables: {
                data: searchParams,
            },
        })

    const transactions = React.useMemo(() => {
        return transactionsData?.transactionsSearch?.transactions || []
    }, [transactionsData?.transactionsSearch?.transactions])

    const totalTransactions = React.useMemo((): number => {
        return transactionsData?.transactionsSearch?.total || 0
    }, [transactionsData?.transactionsSearch?.total])

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'date', headerName: 'Date', flex: 1 },
            { field: 'type', headerName: 'Income/Expense', flex: 1 },
            { field: 'amount', headerName: 'Amount', flex: 1 },
            { field: 'categories', headerName: 'Categories', flex: 1 },
            { field: 'contribution', headerName: 'Contribution', flex: 1 },
            { field: 'account', headerName: 'Account', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        {!row.contribution &&
                            row.type === TransactionType.Income && (
                                <IconButton
                                    onClick={() =>
                                        navigate(`invoice/${row.id}`)
                                    }
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            )}
                    </Grid>
                ),
            },
        ],
        [navigate]
    )

    const rows = React.useMemo(() => {
        return transactions.map((transaction) => {
            return {
                id: transaction.id,
                name: transaction.payment?.supplier
                    ? transaction.payment.supplier?.name
                    : transaction.order?.customer?.fullName,
                date: dayjs(transaction.date).format('DD/MM/YYYY'),
                categories: transactionOrderCategories(transaction),
                contribution: transaction.contribution
                    ? transaction.contribution.customer?.fullName ||
                      'No customer contribution'
                    : null,
                type: transaction.type,
                amount: `${transaction.amount}€`,
                account: transaction.account?.name,
            }
        })
    }, [transactions])

    const onSearch = React.useCallback(
        (filters: TransactionSearchFilters) => {
            setSearchParams({
                ...searchParams,
                filters: filters,
            })
        },
        [searchParams]
    )

    return (
        <>
            <Paginator
                sx={{ marginTop: 2, marginBottom: 2 }}
                total={totalTransactions}
                offset={searchParams?.offset || 0}
                limit={searchParams?.limit || 0}
                onChangeOffset={(offset) => {
                    setSearchParams((prev) => ({
                        ...prev,
                        offset: offset,
                    }))
                }}
                onChangeLimit={(limit) => {
                    setSearchParams((prev) => ({
                        ...prev,
                        limit: limit,
                    }))
                }}
            />
            <TransactionSearchBar onSearch={onSearch} />
            <CustomDataGrid
                columns={columns}
                rows={rows}
                loading={loadingTransactions}
                density="compact"
                hideFooter
            />
        </>
    )
}

export default React.memo(Transactions)
