import Loading from '../../loading'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

export type ComponentProps<
    T extends { id?: string | undefined; label?: string | undefined }
> = {
    selectorKey: string
    loading?: boolean
    fullWidth?: boolean
    selectedItemId?: string
    data: T[]
    setSelectedItem: (item: T | undefined) => void
}

const AutocompleteSelector = <
    T extends { id?: string | undefined; label?: string | undefined }
>({
    selectorKey,
    data,
    setSelectedItem,
    selectedItemId,
    loading,
    fullWidth,
}: ComponentProps<T>) => {
    return loading ? (
        <Loading />
    ) : (
        <Autocomplete
            size="small"
            fullWidth={fullWidth}
            defaultValue={data.find((d) => d.id === selectedItemId)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id={`${selectorKey}-selector`}
            options={data || []}
            renderOption={(props, option) => (
                <Typography
                    {...props}
                    key={`${selectorKey}-selector-option-${option.id}`}
                >
                    {option.label}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField {...params} label={`Select ${selectorKey}`} />
            )}
            onChange={(_, item) => {
                setSelectedItem(item || undefined)
            }}
        />
    )
}

export default AutocompleteSelector
