import React from 'react'
import {
    OrderSearch,
    OrderSearchFilters,
    useDeleteOrderMutation,
    useSearchOrdersQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import { GridColDef } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import { orderCategories, orderPrice } from '../../lib/Order'
import Paginator from '../paginator'
import OrderSearchBar from '../searchBar/orderSearchBar'
import DeleteButton from '../deleteButton'
import dayjs from '../../lib/dayjs'
import CustomDataGrid from '../customDataGrid'

const Orders = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = React.useState<OrderSearch>({
        limit: 25,
        offset: 0,
        filters: {
            searchText: undefined,
            dateRange: {
                from: undefined,
                to: undefined,
            },
            category: undefined,
        },
    })

    const { data: ordersData, loading } = useSearchOrdersQuery({
        variables: {
            data: {
                limit: searchParams.limit,
                offset: searchParams.offset,
                filters: searchParams.filters,
            },
        },
    })
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [deleteOrder, { loading: loadingDelete }] = useDeleteOrderMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteOrder({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getOrders'],
            })
        },
        [deleteOrder, deleting, setDeleteting]
    )

    const orders = React.useMemo(
        () => ordersData?.ordersSearch?.orders || [],
        [ordersData]
    )

    const columns = React.useMemo(
        (): GridColDef[] => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'customer', headerName: 'Customer', flex: 1 },
            {
                field: 'categories',
                headerName: 'Categories',
                flex: 1,
            },
            {
                field: 'total',
                headerName: 'Total',
                flex: 1,
                type: 'number',
                maxWidth: 100,
                valueFormatter: (value) => {
                    return `${parseFloat(value).toFixed(2)} €`
                },
            },
            { field: 'date', type: 'date', headerName: 'Date', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        {row.payed ? (
                            <IconButton disabled={true}>
                                <CreditScoreIcon sx={{ color: 'green' }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => navigate(`pay/${row.id}`)}
                            >
                                <CreditScoreIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={() => navigate(`${row.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton
                                onDelete={handleDelete}
                                item={{
                                    ...row,
                                    name: `order from ${row.customer}`,
                                }}
                            />
                        )}
                    </Grid>
                ),
            },
        ],
        [loadingDelete, deleting, handleDelete, navigate]
    )

    const rows = React.useMemo(() => {
        return orders.map((order) => {
            return {
                id: order.id,
                customer: `${order.customer?.name} ${order.customer?.surname}`,
                date: dayjs(order.date).toDate(), // dayjs(order.date).format('DD/MM/YYYY'),
                categories: orderCategories(order),
                payed: order.transactionId ? true : false,
                total: `${orderPrice(order)}€`,
            }
        })
    }, [orders])

    const onSearch = React.useCallback(
        (filters: OrderSearchFilters) => {
            setSearchParams((prev) => ({
                ...prev,
                filters: filters,
            }))
        },
        [setSearchParams]
    )

    return loading ? (
        <Loading />
    ) : (
        <>
            <Paginator
                sx={{ marginTop: 2, marginBottom: 2 }}
                total={ordersData?.ordersSearch?.total || 0}
                offset={searchParams.offset || 0}
                limit={searchParams.limit || 0}
                onChangeOffset={(offset) =>
                    setSearchParams((prev) => ({ ...prev, offset }))
                }
                onChangeLimit={(limit) =>
                    setSearchParams((prev) => ({ ...prev, limit }))
                }
            />
            <OrderSearchBar
                filters={searchParams.filters}
                onSearch={onSearch}
            />
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
                hideFooter
            />
        </>
    )
}

export default React.memo(Orders)
