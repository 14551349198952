import { createTheme } from '@mui/material'

export const sandColor = '#E2E1DD'

const theme = createTheme({
    palette: {
        primary: {
            main: '#8F9787',
            light: '#B9D5B5',
            dark: '#40483B',
            contrastText: sandColor,
        },
        secondary: {
            main: '#F4C4B7',
            light: '#DBC9BE',
            dark: '#CBACA0',
            contrastText: '#404040',
        },
        background: {
            default: sandColor,
        },
    },
    typography: {
        fontFamily: 'Jura, sans-serif',
        fontSize: 12,
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                sx: {
                    '& .MuiIconButton-root': {
                        '&:hover': {
                            bgcolor: 'primary.light',
                            color: 'primary.dark',
                        },
                    },
                },
            },
        },
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        bgcolor: sandColor,
                        color: 'primary.dark',
                        '& .MuiSvgIcon-root': {
                            color: 'primary.dark',
                        },
                    },
                },
            },
        },
        MuiSvgIcon: {
            defaultProps: {
                sx: {
                    color: 'primary.main',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                sx: {
                    '&:hover': {
                        bgcolor: 'primary.light',
                    },
                },
            },
        },
        // MuiButton: {
        //     defaultProps: {
        //         sx: {
        //             color: sandColor,
        //             bgcolor: 'primary.dark',
        //             '&:hover': {
        //                 bgcolor: 'secondary.dark',
        //             },
        //         },
        //     },
        // },
        MuiListItemButton: {
            defaultProps: {
                sx: {
                    '&:hover': {
                        bgcolor: 'primary.light',
                        color: 'primary.dark',
                    },
                },
            },
        },
    },
})

export default theme
