import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Person,
    useGetPersonQuery,
    useUpdatePersonMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import PersonForm from '../personForm'

const UpdatePerson = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetPerson } = useGetPersonQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updatePerson, { loading: loadingUpdate }] = useUpdatePersonMutation()

    const submit = React.useCallback(
        (person: Person) => {
            updatePerson({
                variables: {
                    data: {
                        id,
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                    },
                },
                onCompleted(data) {
                    navigate('/people')
                },
                refetchQueries: ['getPeople'],
            })
        },
        [navigate, id, updatePerson]
    )

    const personForm = React.useMemo(() => {
        return data?.person ? (
            <PersonForm person={data?.person} submit={submit} />
        ) : null
    }, [data?.person, submit])

    return loadingGetPerson || loadingUpdate ? <Loading /> : personForm
}

export default React.memo(UpdatePerson)
