import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Article,
    Category,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import ItemSelector from '../../selectors/ItemSelector'

export type ComponentProps = {
    article: Article
    submit: (article: Article) => void
}

const ArticleForm = ({ article, submit }: ComponentProps) => {
    const [updatedArticle, setUpdatedArticle] = React.useState<Article>(article)

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery()

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedArticle({
                ...updatedArticle,
                [name]: name === 'price' ? parseFloat(value) : value,
            })
        },
        [updatedArticle, setUpdatedArticle]
    )
    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <TextField
                fullWidth
                value={updatedArticle.name}
                label="Article name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedArticle.price || 0}
                label={'Price'}
                type="decimal"
                name="price"
                onChange={(e) => handleChange(e)}
            />
            <ItemSelector<Category>
                fullWidth
                data={categoriesData?.categories || []}
                selectorKey="category"
                loading={loadingCategories}
                selectedItem={updatedArticle.defaultCategory}
                setSelectedItem={(value: Category | undefined) =>
                    setUpdatedArticle({
                        ...updatedArticle,
                        defaultCategory: value,
                    })
                }
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedArticle)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(ArticleForm)
