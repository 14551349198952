import React from 'react'
import { useParams } from 'react-router-dom'
import {
    useGetTherapistQuery,
    useGetTherapistResumeQuery,
    useSearchSessionsQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import {
    Grid,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import PersonHeader from '../../personHeader'
import { LineChart } from '@mui/x-charts/LineChart'
import HeaderBigNumber from '../../../headerBigNumber'
import HeaderPercentages from '../../../headerPercentages/HeaderPercentages'
import dayjs from '../../../../lib/dayjs'
import CustomDataGrid from '../../../customDataGrid'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const ViewTherapist = () => {
    const params = useParams()
    const [year, setYear] = React.useState<number>(new Date().getFullYear())
    const [month, setMonth] = React.useState<number>(new Date().getMonth())
    const [selectedTab, setSelectedTab] = React.useState<number>(0)

    const { data: therapistData, loading } = useGetTherapistQuery({
        variables: {
            data: {
                id: params.id,
            },
        },
        skip: !params.id,
    })
    const { data: therapistResumeData, loading: resumeLoading } =
        useGetTherapistResumeQuery({
            variables: {
                data: {
                    id: params.id,
                },
            },
            skip: !params.id,
        })

    const { data: sessionsData, loading: loadingSessions } =
        useSearchSessionsQuery({
            variables: {
                data: {
                    limit: 200,
                    offset: 0,
                    filters: {
                        dateRange: {
                            from: new Date(year, month, 1),
                            to: new Date(year, month + 1, 0),
                        },
                        therapistId: params.id,
                    },
                },
            },
            skip: !params.id,
        })

    const yearFacets = React.useMemo(() => {
        const yearsSet = new Set<string>(
            therapistResumeData?.therapistResume?.map((e) => e.year || '') || []
        )
        return Array.from(yearsSet)
    }, [therapistResumeData?.therapistResume])

    const percentageTherapist = React.useMemo((): number => {
        return (therapistData?.therapist?.therapist?.conditions || 0) / 100
    }, [therapistData?.therapist?.therapist?.conditions])

    const percentageIrpfTherapist = React.useMemo((): number => {
        return (therapistData?.therapist?.therapist?.irpf || 0) / 100
    }, [therapistData?.therapist?.therapist?.irpf])

    const yearEntries = React.useMemo(() => {
        return (
            therapistResumeData?.therapistResume?.filter(
                (e) => e.year === year.toString()
            ) || []
        )
    }, [therapistResumeData?.therapistResume, year])

    const selectedMonthValues = React.useMemo((): {
        total: number
        percentageOhana: number
        percentageTherapist: number
        percentageIRPF: number
        totalSessions: number
        monthClients: number
        newClients: number
    } => {
        const values = {
            total: 0,
            percentageOhana: 0,
            percentageTherapist: 0,
            percentageIRPF: 0,
            totalSessions: 0,
            monthClients: 0,
            newClients: 0,
        }
        if (!therapistResumeData?.therapistResume) return values
        const entry = therapistResumeData?.therapistResume.find(
            (e) =>
                e.month === (month + 1).toString() && e.year === year.toString()
        )
        const total = parseFloat(entry?.value || '0')
        const percentTherapist = total * percentageTherapist
        const percentOhana = total - percentTherapist
        const percentageIRPF = percentTherapist * percentageIrpfTherapist // TODO: Change with irpf value
        const sessions = entry?.sessionPersons || 0
        const monthClients = entry?.persons || 0
        const newClients = entry?.newPersons || 0
        return {
            total,
            percentageOhana: percentOhana,
            percentageTherapist: percentTherapist,
            percentageIRPF: percentageIRPF,
            totalSessions: sessions,
            monthClients: monthClients,
            newClients: newClients,
        }
    }, [
        therapistResumeData?.therapistResume,
        year,
        month,
        percentageTherapist,
        percentageIrpfTherapist,
    ])

    const months = React.useMemo(
        () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        []
    )

    const globalInvoiced = React.useMemo((): number => {
        return (
            therapistResumeData?.therapistResume?.reduce((acc, curr) => {
                return acc + parseFloat(curr.value || '0')
            }, 0) || 0
        )
    }, [therapistResumeData?.therapistResume])

    const globalTherapist = React.useMemo((): number => {
        return globalInvoiced * percentageTherapist || 0
    }, [percentageTherapist, globalInvoiced])

    const globalOhana = React.useMemo(
        (): number => globalInvoiced - globalTherapist,
        [globalInvoiced, globalTherapist]
    )

    const globalIrpf = React.useMemo((): number => {
        return globalTherapist * percentageIrpfTherapist || 0
    }, [globalTherapist, percentageIrpfTherapist])

    const sessions = React.useMemo(
        () => sessionsData?.searchSessions?.sessions || [],
        [sessionsData?.searchSessions]
    )

    const yearProgression = React.useMemo(() => {
        if (resumeLoading) return <Loading />

        const serie = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
            const entry = yearEntries.find(
                (e) => e.month === (month + 1).toString()
            )
            return parseFloat(entry?.value || '0')
        })

        return (
            <Paper sx={{ padding: 3 }}>
                <Grid display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'h5'}>
                        Generated every month
                    </Typography>
                </Grid>
                <LineChart
                    xAxis={[
                        {
                            label: 'Month',
                            scaleType: 'time',
                            data: months,
                            tickInterval: months,
                            valueFormatter: (month) => {
                                return dayjs(new Date(2020, month)).format(
                                    'MMM'
                                )
                            },
                        },
                    ]}
                    series={[
                        {
                            data: serie,
                        },
                    ]}
                    height={300}
                />
            </Paper>
        )
    }, [yearEntries, resumeLoading, months])

    const handleTabChange = React.useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    const rows = React.useMemo(() => {
        return sessions.map((session) => {
            return {
                id: session.id,
                name: `${session.customer?.name} ${session.customer?.surname}`,
                date: dayjs(session.date).format('DD/MM/YYYY'),
                duration: `${session.duration}h`,
                transaction: session.transaction?.id,
            }
        })
    }, [sessions])

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'date', headerName: 'Date', flex: 1 },
            { field: 'duration', headerName: 'Duration', flex: 1 },
            { field: 'transaction', headerName: 'Transaction', flex: 1 },
        ],
        []
    )

    const performanceTab = React.useMemo(() => {
        return (
            <Grid display={'flex'} flexDirection={'column'} gap={3}>
                {yearProgression}
                <Grid display={'flex'} flexDirection={'row'} gap={2}>
                    <HeaderBigNumber
                        label={`Invoiced on ${dayjs(
                            new Date(year, month, 1)
                        ).format('MMMM')} ${year}`}
                        value={selectedMonthValues.total}
                    />

                    <HeaderPercentages
                        label={`${dayjs(new Date(year, month, 1)).format(
                            'MMMM'
                        )} ${year}`}
                        percentIRPF={selectedMonthValues.percentageIRPF}
                        percentOhana={selectedMonthValues.percentageOhana}
                        percentTherapist={
                            selectedMonthValues.percentageTherapist
                        }
                    />
                    <HeaderBigNumber
                        label={'Total sessions'}
                        value={selectedMonthValues.totalSessions}
                        symbol="none"
                        isDecimal={false}
                    />
                    <HeaderBigNumber
                        label={'Month clients'}
                        value={selectedMonthValues.monthClients}
                        symbol="none"
                        isDecimal={false}
                    />
                    <HeaderBigNumber
                        label={'New Therapist clients'}
                        value={selectedMonthValues.newClients}
                        symbol="none"
                        isDecimal={false}
                    />
                </Grid>
                <Grid>
                    <CustomDataGrid
                        columns={columns}
                        rows={rows}
                        loading={loadingSessions}
                        density="compact"
                        hideFooter
                    />
                </Grid>
            </Grid>
        )
    }, [
        yearProgression,
        year,
        month,
        rows,
        columns,
        selectedMonthValues.percentageIRPF,
        selectedMonthValues.percentageOhana,
        selectedMonthValues.percentageTherapist,
        selectedMonthValues.monthClients,
        selectedMonthValues.newClients,
        selectedMonthValues.totalSessions,
        selectedMonthValues.total,
        loadingSessions,
    ])

    const moreDataTab = React.useMemo(() => {
        return (
            <Grid>
                <Typography>Aqui hi aniran els formatgets</Typography>
            </Grid>
        )
    }, [])

    const tabsAndSelectors = React.useMemo(() => {
        return (
            <>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Select between performance and more data"
                        centered
                    >
                        <Tab label="Performance" {...a11yProps(0)} />
                        <Tab label="More data" {...a11yProps(1)} />
                    </Tabs>
                    <Grid>
                        <Select
                            value={year}
                            onChange={(e) => {
                                setYear(e.target.value as number)
                            }}
                            size={'small'}
                        >
                            {yearFacets.map((year) => {
                                return (
                                    <MenuItem
                                        value={year}
                                        key={`year-selector-${year}`}
                                    >
                                        {year}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <Select
                            value={month}
                            onChange={(e) => {
                                setMonth(e.target.value as number)
                            }}
                            size={'small'}
                        >
                            <MenuItem value={-1} key={`month-selector-${-1}`}>
                                No month selected
                            </MenuItem>
                            {months.map((month) => {
                                return (
                                    <MenuItem
                                        value={month}
                                        key={`month-selector-${month}`}
                                    >
                                        {dayjs(new Date(year, month, 1)).format(
                                            'MMM'
                                        )}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
                {selectedTab === 0 && performanceTab}
                {selectedTab === 1 && moreDataTab}
            </>
        )
    }, [
        performanceTab,
        moreDataTab,
        selectedTab,
        handleTabChange,
        year,
        yearFacets,
        months,
        month,
    ])

    return loading ? (
        <Loading />
    ) : (
        <Grid>
            {therapistData?.therapist && (
                <Grid display="flex" flexDirection={'column'} gap={3}>
                    <PersonHeader
                        person={therapistData?.therapist}
                        percentIRPF={globalIrpf}
                        percentOhana={globalOhana}
                        percentTherapist={globalTherapist}
                        globalValue={globalInvoiced}
                    />
                    {resumeLoading ? <Loading /> : tabsAndSelectors}
                </Grid>
            )}
        </Grid>
    )
}

export default React.memo(ViewTherapist)
