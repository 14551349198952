import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import React from 'react'

const CustomDataGrid = (props: DataGridProps) => {
    return (
        <DataGrid
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        ...props.initialState?.columns?.columnVisibilityModel,
                        id: false,
                    },
                },
            }}
            {...props}
        />
    )
}

export default React.memo(CustomDataGrid)
