import React from 'react'

import {
    Article,
    CreateArticleMutation,
    useCreateArticleMutation,
} from '../../../generated/graphql'

import Loading from '../../loading'
import ArticleForm from '../articleForm/ArticleForm'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../apollo/resolvers'

const NewArticle = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreateArticleMutation()

    const submit = React.useCallback(
        (article: Article) => {
            create({
                variables: {
                    data: {
                        name: article.name,
                        price: article.price,
                        defaultCategoryId: article.defaultCategory?.id,
                    },
                },
                onCompleted(data: CreateArticleMutation) {
                    addToQuery(data.createArticle, 'articles')
                    navigate(-1)
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <ArticleForm
            article={{
                name: '',
                price: 0,
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewArticle)
