import { Grid, Typography } from '@mui/material'
import React from 'react'
import HeaderContainer from '../headerContainer'

type HeaderBigNumberProps = {
    label: string
    value: number
    symbol?: '€' | '#' | 'none'
    isDecimal?: boolean
}

const HeaderBigNumber = ({
    label,
    value,
    symbol = '€',
    isDecimal = true,
}: HeaderBigNumberProps) => {
    return (
        <HeaderContainer grow={1}>
            <Typography variant={'h5'} sx={{ textAlign: 'center' }}>
                {label}
            </Typography>
            <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ width: '100%', height: '100%' }}
            >
                <Typography sx={{ margin: 0, padding: 0 }} variant={'h2'}>
                    {isDecimal ? value.toFixed(2) : value}
                    {symbol === 'none' ? '' : symbol}
                </Typography>
            </Grid>
        </HeaderContainer>
    )
}

export default React.memo(HeaderBigNumber)
