import React from 'react'
import {
    useDeleteCategoryMutation,
    useGetCategoriesQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import { Box, Button, Grid, IconButton, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import DeleteButton from '../deleteButton'
import CustomDataGrid from '../customDataGrid'

const CategoryList = () => {
    const navigate = useNavigate()
    const { data: categoriesData, loading } = useGetCategoriesQuery()
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [search, setSearch] = React.useState('')
    const [deleteCategory, { loading: loadingDelete }] =
        useDeleteCategoryMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteCategory({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getCategories'],
            })
        },
        [deleteCategory, deleting, setDeleteting]
    )

    const categories = React.useMemo(
        () => categoriesData?.categories || [],
        [categoriesData]
    )

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [loadingDelete, deleting, handleDelete, navigate]
    )

    const rows = React.useMemo(() => {
        const searched = search
            ? categories.filter(
                  (c) =>
                      (c.id?.toLocaleLowerCase() || '').includes(search) ||
                      (c.name?.toLocaleLowerCase() || '').includes(search)
              )
            : categories
        return searched.map((category) => {
            return {
                id: category.id,
                name: category.name,
            }
        })
    }, [categories, search])

    return loading ? (
        <Loading />
    ) : (
        <>
            <Grid
                display={'flex'}
                alignItems={'center'}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size={'small'}
                    fullWidth
                    onChange={(e) =>
                        setSearch((e.target.value || '').toLowerCase())
                    }
                />
                <Button variant="contained" sx={{ marginLeft: 2 }}>
                    seach
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                    onClick={() => navigate('new')}
                >
                    Add
                </Button>
            </Grid>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
            />
        </>
    )
}

export default React.memo(CategoryList)
