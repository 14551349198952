import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const PeopleScreen = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [value, setValue] = React.useState(0)
    const [showTabs, setShowTabs] = React.useState(
        !(
            location.pathname.includes('new') ||
            location.pathname.includes('update')
        )
    )

    React.useEffect(() => {
        let tab = 0

        if (location.pathname === '/people/therapists') {
            tab = 1
        } else if (location.pathname === '/people/customers') {
            tab = 0
        } else if (location.pathname === '/people/organizers') {
            tab = 2
        } else if (location.pathname === '/people') {
            tab = 3
        } else {
            setShowTabs(false)
            return
        }

        setValue(tab)

        setShowTabs(
            !(
                location.pathname.includes('new') ||
                location.pathname.includes('update')
            )
        )
    }, [location.pathname])

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            {showTabs && (
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Customers"
                            {...a11yProps(0)}
                            onClick={() => navigate('/people/customers')}
                        />
                        <Tab
                            label="Therapists"
                            {...a11yProps(1)}
                            onClick={() => navigate('/people/therapists')}
                        />
                        <Tab
                            label="Organizers"
                            {...a11yProps(2)}
                            onClick={() => navigate('/people/organizers')}
                        />
                        <Tab
                            label="People"
                            {...a11yProps(3)}
                            onClick={() => navigate('/people')}
                        />
                    </Tabs>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Button
                            variant={'contained'}
                            onClick={() => navigate(`${location.pathname}/new`)}
                        >
                            Add
                        </Button>
                    </Grid>
                </Box>
            )}
            <Outlet />
        </Box>
    )
}

export default React.memo(PeopleScreen)
