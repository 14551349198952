import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Person,
    useGetOrganizerQuery,
    useUpdateOrganizerMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import OrganizerForm from '../organizerForm'

const UpdateOrganizer = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetOrganizer } = useGetOrganizerQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateOrganizer, { loading: loadingUpdate }] =
        useUpdateOrganizerMutation({
            fetchPolicy: 'network-only',
        })

    const submit = React.useCallback(
        (person: Person) => {
            updateOrganizer({
                variables: {
                    data: {
                        id,
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                        conditions: parseFloat(
                            person?.organizer?.conditions as unknown as string
                        ),
                    },
                },
                onCompleted() {
                    navigate('/people/organizers')
                },
                refetchQueries: ['getOrganizers', 'getPeople'],
            })
        },
        [navigate, id, updateOrganizer]
    )

    const organizerForm = React.useMemo(() => {
        return data?.organizer ? (
            <OrganizerForm organizer={data?.organizer} submit={submit} />
        ) : null
    }, [data?.organizer, submit])

    return loadingGetOrganizer || loadingUpdate ? <Loading /> : organizerForm
}

export default React.memo(UpdateOrganizer)
