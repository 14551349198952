import Grid from '@mui/material/Grid'
import React from 'react'
import OrganizersTable from './organizersTable/OrganizersTable'

const Organizers = () => {
    return (
        <Grid>
            <OrganizersTable />
        </Grid>
    )
}

export default React.memo(Organizers)
