import React from 'react'
import {
    Person,
    SessionSearch,
    SessionSearchFilters,
    useSearchSessionsQuery,
} from '../../generated/graphql'
import { GridColDef } from '@mui/x-data-grid'
import Loading from '../loading'
import Paginator from '../paginator'
import CustomDataGrid from '../customDataGrid'
import dayjs from '../../lib/dayjs'
import SessionActionButtons from './sessionActionButtons'
import SessionSearchBar from '../searchBar/sessionSearchBar'

const Sessions = () => {
    const [searchParams, setSearchParams] = React.useState<SessionSearch>({
        limit: 25,
        offset: 0,
        filters: {
            categoryId: undefined,
            dateRange: {
                from: undefined,
                to: undefined,
            },
            therapistId: undefined,
            customerId: undefined,
        },
    })

    const {
        data: sessionsData,
        loading,
        refetch,
    } = useSearchSessionsQuery({
        variables: {
            data: {
                limit: searchParams.limit,
                offset: searchParams.offset,
                filters: searchParams.filters,
            },
        },
    })

    const sessions = React.useMemo(
        () => sessionsData?.searchSessions?.sessions || [],
        [sessionsData]
    )

    const columns = React.useMemo(
        (): GridColDef[] => [
            { field: 'id', headerName: 'ID' },
            {
                field: 'date',
                headerName: 'Date',
                minWidth: 150,
                valueFormatter: (value) => {
                    return dayjs(value).utc().format('DD/MM/YYYY HH:mm')
                },
            },
            {
                field: 'customer',
                headerName: 'Customer',
                flex: 1,
                valueFormatter: (value: Person) => {
                    return value.fullName
                },
            },
            {
                field: 'therapist',
                headerName: 'Therapist',
                flex: 1,
                valueFormatter: (value: Person) => {
                    return value.fullName
                },
            },

            {
                field: 'duration',
                headerName: 'Duration',
                flex: 1,
                maxWidth: 100,
                valueFormatter: (value) => {
                    return `${parseFloat(value)}h`
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                align: 'center',
                renderCell: ({ row: session }: { row: any }) => (
                    <SessionActionButtons session={session} refetch={refetch} />
                ),
            },
        ],
        [refetch]
    )

    const rows = React.useMemo(() => {
        return sessions
    }, [sessions])

    const onSearch = React.useCallback(
        (filters: SessionSearchFilters) => {
            setSearchParams((prev) => ({
                ...prev,
                filters: filters,
            }))
        },
        [setSearchParams]
    )

    return loading ? (
        <Loading />
    ) : (
        <>
            <Paginator
                sx={{ marginTop: 2, marginBottom: 2 }}
                total={sessionsData?.searchSessions?.total || 0}
                offset={searchParams.offset || 0}
                limit={searchParams.limit || 0}
                onChangeOffset={(offset) =>
                    setSearchParams((prev) => ({ ...prev, offset }))
                }
                onChangeLimit={(limit) =>
                    setSearchParams((prev) => ({ ...prev, limit }))
                }
            />
            <SessionSearchBar
                filters={searchParams.filters}
                onSearch={onSearch}
            />
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
                hideFooter
            />
        </>
    )
}

export default React.memo(Sessions)
