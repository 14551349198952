import Grid from '@mui/material/Grid'
import React from 'react'
import { Outlet } from 'react-router-dom'

const SuppliersScreen = () => {
    return (
        <Grid flexDirection={'column'}>
            <Outlet />
        </Grid>
    )
}

export default React.memo(SuppliersScreen)
