import { Button, Switch, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Person, useGetCategoriesQuery } from '../../../../generated/graphql'
import ItemSelector from '../../../selectors/ItemSelector'

export type ComponentProps = {
    therapist: Person
    submit: (person: Person) => void
}

const TherapistForm = ({ therapist, submit }: ComponentProps) => {
    const [person, setPerson] = React.useState<Person>(therapist)

    const { data: categoriesData, loading: categoriesLoading } =
        useGetCategoriesQuery()

    const handlePersonChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setPerson({
                ...person,
                [e.target.name]: e.target.value,
            })
        },
        [person, setPerson]
    )

    const handleTherapistChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const therapist = {
                ...person.therapist,
                [e.target.name]: e.target.value,
            }
            setPerson({
                ...person,
                therapist,
            })
        },
        [person, setPerson]
    )
    const handleTherapistFloatChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const therapist = {
                ...person.therapist,
                [e.target.name]: parseInt(e.target.value),
            }
            setPerson({
                ...person,
                therapist,
            })
        },
        [person, setPerson]
    )

    const handleDisableTherapist = React.useCallback(() => {
        const therapist = {
            ...person.therapist,
            disabled: !person.therapist?.disabled,
        }
        setPerson({
            ...person,
            therapist,
        })
    }, [person, setPerson])

    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <Grid
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'left'}
                alignItems={'center'}
            >
                <Switch
                    color="secondary"
                    checked={person.therapist?.disabled}
                    onChange={handleDisableTherapist}
                />
                <Typography>Therapist is disabled</Typography>
            </Grid>
            <TextField
                fullWidth
                value={person.name}
                label="Name"
                type="text"
                name="name"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.surname}
                label="Last name"
                type="text"
                name="surname"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.email}
                label="Email"
                type="email"
                name="email"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.phone}
                label="Phone"
                type="phone"
                name="phone"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.dni}
                label="DNI"
                type="text"
                name="dni"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.defaultPrice}
                label="Price €"
                type="number"
                name="defaultPrice"
                onChange={(e) => handleTherapistFloatChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.conditions}
                label="Conditions %"
                type="number"
                name="conditions"
                onChange={(e) => handleTherapistFloatChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.irpf}
                label="IRPF %"
                type="number"
                name="irpf"
                onChange={(e) => handleTherapistFloatChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.licenseCode}
                label="license code"
                type="text"
                name="licenseCode"
                onChange={(e) => handleTherapistChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.calendarId}
                label="Calendar id to sync with therapist"
                type="text"
                name="calendarId"
                onChange={(e) => handleTherapistChange(e)}
            />

            <TextField
                fullWidth
                value={person?.therapist?.nextCalendarTokenSync}
                label="Next sync Token for google calendar"
                disabled={true}
                type="text"
                name="nextCalendarTokenSync"
                onChange={(e) => handleTherapistChange(e)}
            />

            <ItemSelector
                selectorKey="category"
                data={categoriesData?.categories || []}
                selectedItem={person?.therapist?.defaultCategory}
                loading={categoriesLoading}
                setSelectedItem={(category) => {
                    setPerson({
                        ...person,
                        therapist: {
                            ...person.therapist,
                            defaultCategory: category,
                        },
                    })
                }}
            />

            <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => submit(person)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(TherapistForm)
