import ListItemButton from '@mui/material/ListItemButton'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ListItemIcon from '@mui/material/ListItemIcon'
import CategoryIcon from '@mui/icons-material/Category'
import SpaIcon from '@mui/icons-material/Spa'
import PeopleIcon from '@mui/icons-material/People'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import PaymentsIcon from '@mui/icons-material/Payments'
import StoreIcon from '@mui/icons-material/Store'
import PaidIcon from '@mui/icons-material/Paid'
// import SchoolIcon from '@mui/icons-material/School'
import PsychologyIcon from '@mui/icons-material/Psychology'
import OrderIcon from '@mui/icons-material/Description'
import StorefrontIcon from '@mui/icons-material/Storefront'
import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { Divider } from '@mui/material'
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek'

const MenuItems = () => {
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <ListItemButton onClick={() => navigate('/')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <Divider />
            {/* TODO: Uncomment this to continue with activities*/}
            {/* <ListItemButton onClick={() => navigate('/activities')}>
                <ListItemIcon>
                    <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="Activities" />
            </ListItemButton> */}

            <ListItemButton onClick={() => navigate('/people/customers')}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/people/therapists')}>
                <ListItemIcon>
                    <PsychologyIcon />
                </ListItemIcon>
                <ListItemText primary="Therapists" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/suppliers')}>
                <ListItemIcon>
                    <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Suppliers" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => navigate('/sessions')}>
                <ListItemIcon>
                    <SpaIcon />
                </ListItemIcon>
                <ListItemText primary="Sessions" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/sessions/calendar')}>
                <ListItemIcon>
                    <CalendarViewWeekIcon />
                </ListItemIcon>
                <ListItemText primary="Weekly calendar" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/transactions')}>
                <ListItemIcon>
                    <PaidIcon />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/orders')}>
                <ListItemIcon>
                    <OrderIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => navigate('/articles')}>
                <ListItemIcon>
                    <StorefrontIcon />
                </ListItemIcon>
                <ListItemText primary="Articles" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/categories')}>
                <ListItemIcon>
                    <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Categories" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/accounts')}>
                <ListItemIcon>
                    <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Bank Accounts" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/payment-methods')}>
                <ListItemIcon>
                    <PaymentsIcon />
                </ListItemIcon>
                <ListItemText primary="Payment Methods" />
            </ListItemButton>
        </React.Fragment>
    )
}

export default React.memo(MenuItems)
