import React from 'react'
import { ThemeProvider } from '@emotion/react'
import theme from './theme'
import { RouterProvider } from 'react-router-dom'
import { rootRouter } from './routers'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { SnackbarProvider } from 'notistack'

const App = () => {
    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        >
            <SnackbarProvider />
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <RouterProvider router={rootRouter} />
                </ThemeProvider>
            </ApolloProvider>
        </GoogleOAuthProvider>
    )
}

export default React.memo(App)
