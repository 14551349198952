import React from 'react'
import { Person } from '../../../generated/graphql'
import { Divider, Grid, Typography } from '@mui/material'
import HeaderBigNumber from '../../headerBigNumber'
import HeaderContainer from '../../headerContainer'
import HeaderPercentages from '../../headerPercentages/HeaderPercentages'

export type ComponentProps<T extends Person> = {
    person: T
    globalValue?: number
    percentOhana?: number
    percentTherapist?: number
    percentIRPF?: number
}

const PersonHeader = <T extends Person>({
    person,
    globalValue,
    percentOhana,
    percentTherapist,
    percentIRPF,
}: ComponentProps<T>) => {
    const personDetails = React.useMemo(() => {
        return (
            <>
                <Typography variant={'h4'}>{person.fullName}</Typography>
                <Divider sx={{ marginBottom: 3 }}></Divider>
                <Grid display={'flex'}>
                    <Grid flexGrow={1}>
                        <Typography variant="h6">
                            <b>DNI:</b> {person.dni}
                        </Typography>
                        <Typography variant="h6">
                            <b>Email:</b> {person.email}
                        </Typography>
                        <Typography variant="h6">
                            <b>Phone:</b> {person.phone}
                        </Typography>
                        {person.therapist && (
                            <Typography variant="h6">
                                <b>Default Category:</b>{' '}
                                {person.therapist?.defaultCategory?.name}
                            </Typography>
                        )}
                    </Grid>
                    {person.therapist && (
                        <Grid flexGrow={1}>
                            <Typography variant="h6">
                                <b>Price:</b>{' '}
                                {(person.therapist.defaultPrice || 0).toFixed(
                                    2
                                )}{' '}
                                €
                            </Typography>
                            <Typography variant="h6">
                                <b>Conditions:</b> {person.therapist.conditions}
                                %
                            </Typography>
                            <Typography variant="h6">
                                <b>IRPF:</b> {person.therapist.irpf}%
                            </Typography>
                            <Typography variant="h6">
                                <b>License code:</b>{' '}
                                {person.therapist.licenseCode}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </>
        )
    }, [person])

    return (
        <Grid display={'flex'} gap={3} sx={{ minHeight: '200px' }}>
            <HeaderContainer grow={1}>{personDetails}</HeaderContainer>
            {globalValue && (
                <HeaderBigNumber label={`Total invoiced`} value={globalValue} />
            )}
            {percentIRPF && percentOhana && percentTherapist && (
                <HeaderPercentages
                    percentIRPF={percentIRPF}
                    percentOhana={percentOhana}
                    percentTherapist={percentTherapist}
                />
            )}
        </Grid>
    )
}

export default React.memo(PersonHeader)
