import React from 'react'
import {
    Category,
    Person,
    SessionSearchFilters,
    useGetCategoriesQuery,
    useGetCustomersQuery,
    useGetTherapistsQuery,
} from '../../../generated/graphql'
import { Button, Grid } from '@mui/material'
import DateField from '../../pickers/dateField'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'

export type ComponentProps = {
    filters: SessionSearchFilters | undefined
    onSearch(filters: SessionSearchFilters): void
}

const SessionSearchBar = ({ filters, onSearch }: ComponentProps) => {
    const { data: customersData, loading: loadingCustomers } =
        useGetCustomersQuery()

    const { data: therapistData, loading: loadingTherapists } =
        useGetTherapistsQuery()

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery()

    const customers = React.useMemo(() => {
        return customersData?.customers || []
    }, [customersData?.customers])

    const therapists = React.useMemo(() => {
        return therapistData?.therapists || []
    }, [therapistData?.therapists])

    const [tmpFilters, setTmpFilters] = React.useState<SessionSearchFilters>(
        filters || {}
    )

    return (
        <Grid
            component="form"
            onSubmit={(e) => {
                e.preventDefault()
                onSearch(tmpFilters)
            }}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid flexGrow={1}>
                <AutocompleteSelector<Person>
                    data={
                        customers.map((c) => ({
                            label: `${c.name} ${c.surname}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={tmpFilters?.customerId || undefined}
                    selectorKey="customer"
                    setSelectedItem={(item: Person | undefined) => {
                        setTmpFilters((prev) => ({
                            ...prev,
                            customerId: item?.id,
                        }))
                    }}
                    loading={loadingCustomers}
                />
            </Grid>

            <Grid flexGrow={1}>
                <AutocompleteSelector<Person>
                    data={
                        therapists.map((c) => ({
                            label: `${c.name} ${c.surname}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={tmpFilters?.therapistId || undefined}
                    selectorKey="therapist"
                    setSelectedItem={(item: Person | undefined) => {
                        setTmpFilters((prev) => ({
                            ...prev,
                            therapistId: item?.id,
                        }))
                    }}
                    loading={loadingTherapists}
                />
            </Grid>
            <Grid flexGrow={1}>
                <AutocompleteSelector<Category>
                    data={
                        categoriesData?.categories?.map((c) => ({
                            label: `${c.name}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={tmpFilters?.categoryId}
                    selectorKey="category"
                    setSelectedItem={(category: Category | undefined) =>
                        setTmpFilters((prev) => ({
                            ...prev,
                            categoryId: category?.id,
                        }))
                    }
                    loading={loadingCategories}
                />
            </Grid>

            <Grid flexGrow={1}>
                <DateField
                    value={tmpFilters.dateRange?.from}
                    onChange={(newValue) =>
                        setTmpFilters((prev) => ({
                            ...prev,
                            dateRange: {
                                ...prev.dateRange,
                                from: newValue,
                            },
                        }))
                    }
                />
            </Grid>
            <Grid flexGrow={1}>
                <DateField
                    label={'Date to'}
                    value={tmpFilters.dateRange?.to}
                    onChange={(newDate) =>
                        setTmpFilters((prev) => ({
                            ...prev,
                            dateRange: {
                                ...prev.dateRange,
                                to: newDate,
                            },
                        }))
                    }
                />
            </Grid>
            <Button variant={'contained'} size={'small'} type="submit">
                Search
            </Button>
        </Grid>
    )
}

export default React.memo(SessionSearchBar)
