import { Button, MenuItem, Select, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import ItemSelector from '../../selectors/ItemSelector/ItemSelector'
import {
    Category,
    TransactionSearchFilters,
    TransactionType,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import DateField from '../../pickers/dateField'

export type ComponentProps = {
    onSearch(filters: TransactionSearchFilters): void
}

const TransactionSearchBar = ({ onSearch }: ComponentProps) => {
    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery({})

    const [filters, setFilters] = React.useState<TransactionSearchFilters>({
        searchText: undefined,
        category: undefined,
        account: undefined,
        transactionType: undefined,
        paymentMethod: undefined,
        dateRange: {
            from: undefined,
            to: undefined,
        },
        amountRange: {
            from: undefined,
            to: undefined,
        },
    })
    return (
        <Grid
            component="form"
            onSubmit={(e) => {
                e.preventDefault()
                onSearch(filters)
            }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid display={'flex'} alignItems={'center'} gap={2}>
                <Grid flexGrow={1}>
                    <TextField
                        size="small"
                        fullWidth
                        id="search"
                        label="Search"
                        variant="outlined"
                        value={filters.searchText || ''}
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                searchText: (e.target as HTMLInputElement)
                                    .value,
                            })
                        }
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <ItemSelector<Category>
                        fullWidth
                        data={categoriesData?.categories || []}
                        selectorKey="category"
                        loading={loadingCategories}
                        selectedItem={
                            categoriesData?.categories?.find(
                                (cat) => cat.name === filters.category
                            ) || undefined
                        }
                        setSelectedItem={(category: Category | undefined) =>
                            setFilters((prev) => ({
                                ...prev,
                                category: category?.name,
                            }))
                        }
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`transactionType-selector"`}
                        id={`transactionType-selector"`}
                        value={filters.transactionType || 'empty'}
                        onChange={(event) => {
                            setFilters((prev) => ({
                                ...prev,
                                transactionType:
                                    event.target.value === 'empty'
                                        ? undefined
                                        : (event.target
                                              .value as TransactionType),
                            }))
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-transactionType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select transaction type
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-income`}
                            value={TransactionType.Income}
                        >
                            {' '}
                            {TransactionType.Income}
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-transactionType-income`}
                            value={TransactionType.Expense}
                        >
                            {' '}
                            {TransactionType.Expense}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <DateField
                        value={filters.dateRange?.from}
                        onChange={(newValue) =>
                            setFilters((prev) => ({
                                ...prev,
                                dateRange: {
                                    ...prev.dateRange,
                                    from: newValue,
                                },
                            }))
                        }
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <DateField
                        label={'Date to'}
                        value={filters.dateRange?.to}
                        onChange={(newDate) =>
                            setFilters((prev) => ({
                                ...prev,
                                dateRange: {
                                    ...prev.dateRange,
                                    to: newDate,
                                },
                            }))
                        }
                    />
                </Grid>
                <Button type={'submit'} variant={'contained'} size={'small'}>
                    SEARCH
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(TransactionSearchBar)
