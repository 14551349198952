import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Person } from '../../../../generated/graphql'

export type ComponentProps = {
    organizer: Person
    submit: (person: Person) => void
}

const OrganizerForm = ({ organizer, submit }: ComponentProps) => {
    const [person, setPerson] = React.useState<Person>(organizer)

    const handlePersonChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setPerson({
                ...person,
                [e.target.name]: e.target.value,
            })
        },
        [person, setPerson]
    )

    const handleOrganizerChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const organizer = {
                ...person.organizer,
                [e.target.name]: e.target.value,
            }
            setPerson({
                ...person,
                organizer: organizer,
            })
        },
        [person, setPerson]
    )

    return (
        <Grid
            container
            gap={3}
            flexDirection={'column'}
            component="form"
            onSubmit={() => submit(person)}
        >
            <TextField
                fullWidth
                value={person.name}
                label="Name"
                type="text"
                name="name"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.surname}
                label="Surname"
                type="text"
                name="surname"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.email}
                label="Email"
                type="email"
                name="email"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.phone}
                label="Phone"
                type="phone"
                name="phone"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.dni}
                label="DNI"
                type="text"
                name="dni"
                onChange={(e) => handlePersonChange(e)}
            />

            <TextField
                fullWidth
                value={person.organizer?.conditions}
                label="Conditions (percentage for the organizer)"
                type="number"
                name="conditions"
                onChange={(e) => handleOrganizerChange(e)}
            />

            <Button variant="contained" color="primary" type="submit">
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(OrganizerForm)
