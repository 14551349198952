import React from 'react'

import {
    CreateOrderMutation,
    Order,
    useCreateOrderMutation,
    useGetArticleQuery,
    useGetCustomerQuery,
    useGetSessionQuery,
} from '../../../generated/graphql'

import Loading from '../../loading'
import OrderForm from '../orderForm/OrderForm'
import { useNavigate, useParams } from 'react-router-dom'
import { addToQuery } from '../../../apollo/resolvers'

const NewOrder = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { data: sessionData, loading: sessionLoading } = useGetSessionQuery({
        skip: params.orderType !== 'session',
        variables: {
            data: { id: params.id },
        },
    })

    const { data: customerData, loading: customerLoading } =
        useGetCustomerQuery({
            skip: params.orderType !== 'customer',
            variables: {
                data: { id: params.id },
            },
        })

    const { data: articleData, loading: articleLoading } = useGetArticleQuery({
        skip: params.orderType !== 'article',
        variables: {
            data: { id: params.id },
        },
    })

    const [create, { loading }] = useCreateOrderMutation()

    const submit = React.useCallback(
        (order: Order, pay: boolean) => {
            create({
                variables: {
                    data: {
                        date: order.date,
                        rows: (order.rows || []).map((row) => ({
                            amount: row.amount,
                            price: row.price || row.article?.price,
                            articleId: row.article?.id,
                            sessionId: row.session?.id,
                        })),
                        customerId: order.customer?.id,
                    },
                },
                onCompleted(data: CreateOrderMutation) {
                    addToQuery(data.createOrder, 'orders')
                    if (pay) {
                        navigate(`/orders/pay/${data.createOrder?.id}`)
                    } else {
                        navigate('/orders')
                    }
                },
            })
        },
        [create, navigate]
    )

    const rows = React.useMemo(() => {
        const session = sessionData?.session
        if (session) {
            return [
                {
                    session,
                    amount: sessionData?.session?.duration,
                },
            ]
        }
        if (articleData) {
            return [
                {
                    article: articleData.article,
                    amount: 1,
                },
            ]
        }
        return []
    }, [sessionData, articleData])

    return loading || customerLoading || articleLoading || sessionLoading ? (
        <Loading />
    ) : (
        <OrderForm
            order={{
                date:
                    sessionData?.session?.date.toString() ||
                    new Date().toString(),
                rows: rows,
                customer:
                    sessionData?.session?.customer ||
                    customerData?.customer ||
                    undefined,
                isPayed: false,
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewOrder)
