import {
    Button,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Person } from '../../../../generated/graphql'
import DateField from '../../../pickers/dateField'

export type ComponentProps = {
    customer: Person
    submit: (person: Person) => void
}

const CustomerForm = ({ customer, submit }: ComponentProps) => {
    const [person, setPerson] = React.useState<Person>(customer)

    const handlePersonChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setPerson({
                ...person,
                [e.target.name]: e.target.value,
            })
        },
        [person, setPerson]
    )

    const handleCustomerChange = React.useCallback(
        (field: string, value: string) => {
            setPerson({
                ...person,
                customer: {
                    ...person.customer,
                    [field]: value,
                },
            })
        },
        [person]
    )

    return (
        <Grid
            container
            gap={3}
            flexDirection={'column'}
            component="form"
            onSubmit={() => submit(person)}
        >
            <TextField
                fullWidth
                value={person.name}
                label="Name"
                type="text"
                name="name"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.surname}
                label="Surname"
                type="text"
                name="surname"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.email}
                label="Email"
                type="email"
                name="email"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.phone}
                label="Phone"
                type="phone"
                name="phone"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.city}
                label="City"
                type="text"
                name="city"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.dni}
                label="DNI"
                type="text"
                name="dni"
                onChange={(e) => handlePersonChange(e)}
                size={'small'}
            />
            <Grid
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                maxWidth={300}
                justifyContent={'space-between'}
            >
                <Typography>Diffusion</Typography>
                <Select
                    value={person.customer?.diffusion || undefined}
                    onChange={(e) => {
                        handleCustomerChange('diffusion', e.target.value)
                    }}
                    size={'small'}
                >
                    <MenuItem value={undefined} key={`diffusion-option-empty`}>
                        Null
                    </MenuItem>
                    <MenuItem value={'S'} key={`diffusion-option-S`}>
                        S
                    </MenuItem>
                    <MenuItem value={'Pdt'} key={`diffusion-option-Pdt`}>
                        Pdt
                    </MenuItem>
                </Select>
            </Grid>
            <Grid
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                maxWidth={300}
                justifyContent={'space-between'}
            >
                <Typography>Send Information</Typography>
                <Switch
                    checked={person.customer?.wantReceiveInfo}
                    onChange={(e) => {
                        setPerson({
                            ...person,
                            customer: {
                                ...person.customer,
                                wantReceiveInfo: e.target.checked,
                            },
                        })
                    }}
                ></Switch>
            </Grid>
            <Grid
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                maxWidth={300}
                justifyContent={'space-between'}
            >
                <Typography>Image rights</Typography>
                <Switch
                    checked={person.customer?.imageRights}
                    onChange={(e) => {
                        setPerson({
                            ...person,
                            customer: {
                                ...person.customer,
                                imageRights: e.target.checked,
                            },
                        })
                    }}
                ></Switch>
            </Grid>
            <Grid
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                maxWidth={300}
                justifyContent={'space-between'}
            >
                <Typography>Document type</Typography>
                <Select
                    value={person.customer?.documentType || undefined}
                    onChange={(e) => {
                        handleCustomerChange('documentType', e.target.value)
                    }}
                    size={'small'}
                >
                    <MenuItem value={'File'} key={`document-type-option-file`}>
                        File
                    </MenuItem>
                    <MenuItem
                        value={'Signaturit'}
                        key={`document-type-option-signaturit`}
                    >
                        Signaturit
                    </MenuItem>
                </Select>
            </Grid>
            <DateField
                value={person.customer?.signatureDate}
                label="Signature date"
                onChange={(value) => {
                    setPerson({
                        ...person,
                        customer: {
                            ...person.customer,
                            signatureDate: value,
                        },
                    })
                }}
            />
            <TextField
                fullWidth
                value={person.customer?.contactChannel}
                label="Contact channel"
                type="text"
                name="contactChannel"
                onChange={(e) =>
                    handleCustomerChange('contactChannel', e.target.value)
                }
                size={'small'}
            />
            <TextField
                fullWidth
                value={person.customer?.howDiscovered}
                label="How did they know us?"
                type="text"
                name="howDiscovered"
                onChange={(e) =>
                    handleCustomerChange('howDiscovered', e.target.value)
                }
                size={'small'}
            />
            <Button variant="contained" color="primary" type="submit">
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(CustomerForm)
