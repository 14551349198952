import React from 'react'
import TherapistForm from '../therapistForm'
import {
    CreateTherapistMutation,
    Person,
    useCreateTherapistMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../../apollo/resolvers'

const NewTherapist = () => {
    const navigate = useNavigate()
    const [create, { loading }] = useCreateTherapistMutation()

    const submit = React.useCallback(
        (person: Person) => {
            create({
                variables: {
                    data: {
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                        conditions: person?.therapist?.conditions,
                        licenseCode: person?.therapist?.licenseCode,
                        irpf: person?.therapist?.irpf,
                        defaultCategoryId:
                            person?.therapist?.defaultCategory?.id,
                        defaultPrice: person?.therapist?.defaultPrice,
                        calendarId: person?.therapist?.calendarId,
                    },
                },
                onCompleted: (data: CreateTherapistMutation) => {
                    addToQuery(data.createTherapist, 'people')
                    addToQuery(data.createTherapist, 'therapists')
                    navigate('/people/therapists')
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <TherapistForm
            therapist={{
                name: '',
                email: '',
                phone: '',
                dni: '',
                therapist: {
                    conditions: 0,
                    licenseCode: '',
                    defaultCategory: undefined,
                    defaultPrice: 0,
                },
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewTherapist)
