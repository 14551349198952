import { GraphQLError } from 'graphql'
import { enqueueSnackbar } from 'notistack'

export const enqueueError = (err: GraphQLError) => {
    const splitted = err.message.split('\n')
    if (splitted.length === 0) return

    const replaced = splitted[0]
    enqueueSnackbar(replaced, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
    })
}
