import React from 'react'
import SessionForm from '../sessionForm/SessionForm'
import {
    CreateSessionMutation,
    Session,
    useCreateSessionMutation,
} from '../../../generated/graphql'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../loading'
import omit from 'lodash/omit'
import { addToQuery } from '../../../apollo/resolvers'
import dayjs from '../../../lib/dayjs'

const NewSession = () => {
    const navigate = useNavigate()
    const { state: customer } = useLocation()

    const [create, { loading }] = useCreateSessionMutation({
        fetchPolicy: 'network-only',
    })

    const submit = React.useCallback(
        (session: Session) => {
            create({
                variables: {
                    data: {
                        ...omit(session, [
                            'customer',
                            'therapist',
                            'article',
                            'category',
                        ]),
                        customerId: session.customer?.id,
                        therapistId: session.therapist?.id,
                        categoryId:
                            session.therapist?.therapist?.defaultCategory?.id,
                    },
                },
                onCompleted(data: CreateSessionMutation) {
                    addToQuery(data.createSession, 'sessions')
                    navigate(-1)
                },
                refetchQueries: ['getSessions'],
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <SessionForm
            session={{
                date: dayjs(new Date())
                    .utc()
                    .add(1, 'day')
                    .hour(12)
                    .minute(0)
                    .toDate(),
                duration: 1,
                notes: '',
                therapist: undefined,
                customer: customer || undefined,
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewSession)
