import { TypePolicy } from '@apollo/client'

export const Person: TypePolicy = {
    fields: {
        fullName: {
            read(_, { readField }) {
                const firstName = readField('name')
                const lastName = readField('surname')
                return `${firstName} ${lastName}`
            },
        },
    },
}
