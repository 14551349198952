import React from 'react'
import {
    Category,
    OrderSearchFilters,
    OrderType,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'
import DateField from '../../pickers/dateField'

export type ComponentProps = {
    filters: OrderSearchFilters | undefined
    onSearch(filters: OrderSearchFilters): void
}

const OrderSearchBar = ({ filters, onSearch }: ComponentProps) => {
    const [tmpFilters, setTmpFilters] = React.useState<OrderSearchFilters>(
        filters || {}
    )

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery()

    return (
        <Grid
            component="form"
            onSubmit={(e) => {
                e.preventDefault()
                onSearch(tmpFilters)
            }}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid display={'flex'} alignItems={'center'} gap={2}>
                <Grid flexGrow={1}>
                    <TextField
                        size="small"
                        fullWidth
                        id="search"
                        label="Search"
                        variant="outlined"
                        value={tmpFilters?.searchText || ''}
                        onChange={(e) =>
                            setTmpFilters((prev) => ({
                                ...prev,
                                searchText: e.target.value,
                            }))
                        }
                    />
                </Grid>

                <Grid flexGrow={1}>
                    <AutocompleteSelector<Category>
                        data={
                            categoriesData?.categories?.map((c) => ({
                                label: `${c.name}`,
                                id: c.id,
                            })) || []
                        }
                        fullWidth
                        selectedItemId={tmpFilters?.category}
                        selectorKey="category"
                        setSelectedItem={(category: Category | undefined) =>
                            setTmpFilters((prev) => ({
                                ...prev,
                                category: category?.id,
                            }))
                        }
                        loading={loadingCategories}
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`orderType-selector"`}
                        id={`orderType-selector"`}
                        value={tmpFilters.orderType || 'empty'}
                        onChange={(event) => {
                            setTmpFilters((prev) => ({
                                ...prev,
                                orderType:
                                    event.target.value === 'empty'
                                        ? undefined
                                        : (event.target.value as OrderType),
                            }))
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-orderType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select order type
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-article`}
                            value={OrderType.Article}
                        >
                            {' '}
                            {OrderType.Article}
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-orderType-income`}
                            value={OrderType.Session}
                        >
                            {' '}
                            {OrderType.Session}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`orderPayed-selector"`}
                        id={`orderPayed-selector"`}
                        value={
                            tmpFilters.payed === undefined
                                ? 'empty'
                                : tmpFilters.payed === true
                                ? 'payed'
                                : 'not payed'
                        }
                        onChange={(event) => {
                            const value = event.target.value
                            setTmpFilters((prev) => ({
                                ...prev,
                                payed:
                                    value === 'empty'
                                        ? undefined
                                        : value === 'payed',
                            }))
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-orderType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select Payed or not
                        </MenuItem>
                        <MenuItem key={`menuItemKey-article`} value={'payed'}>
                            {' '}
                            Payed
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-orderType-income`}
                            value={'not payed'}
                        >
                            {' '}
                            Not Payed
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <DateField
                        value={tmpFilters.dateRange?.from}
                        onChange={(newValue) =>
                            setTmpFilters((prev) => ({
                                ...prev,
                                dateRange: {
                                    ...prev.dateRange,
                                    from: newValue,
                                },
                            }))
                        }
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <DateField
                        label={'Date to'}
                        value={tmpFilters.dateRange?.to}
                        onChange={(newDate) =>
                            setTmpFilters((prev) => ({
                                ...prev,
                                dateRange: {
                                    ...prev.dateRange,
                                    to: newDate,
                                },
                            }))
                        }
                    />
                </Grid>
                <Button variant={'contained'} size={'small'} type="submit">
                    Search
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(OrderSearchBar)
