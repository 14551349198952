import React from 'react'
import OrganizerForm from '../organizerForm'
import {
    CreateOrganizerMutation,
    Person,
    useCreateOrganizerMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../../apollo/resolvers'

const NewOrganizer = () => {
    const navigate = useNavigate()
    const [create, { loading }] = useCreateOrganizerMutation()

    const submit = React.useCallback(
        (person: Person) => {
            create({
                variables: {
                    data: {
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                        conditions: parseFloat(
                            person?.organizer?.conditions as unknown as string
                        ),
                    },
                },
                onCompleted: (data: CreateOrganizerMutation) => {
                    addToQuery(data.createOrganizer, 'people')
                    addToQuery(data.createOrganizer, 'organizers')
                    navigate('/people/organizers')
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <OrganizerForm
            organizer={{
                name: '',
                email: '',
                phone: '',
                dni: '',
                organizer: {
                    conditions: 0,
                },
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewOrganizer)
