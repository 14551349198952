import React from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import useAuthrorization from '../../hooks/useAuthorization'
type ComponentProps = {
    children: React.ReactNode
}

const BaseScreen = ({ children }: ComponentProps) => {
    const navigate = useNavigate()

    const { loading, error } = useAuthrorization()

    React.useEffect(() => {
        if (error) {
            navigate('/login')
        }
    }, [error, navigate])

    return loading || error ? (
        <CircularProgress />
    ) : (
        <div id="baseScreen">{children}</div>
    )
}

export default React.memo(BaseScreen)
