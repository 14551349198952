import {
    ApolloClient,
    InMemoryCache,
    InMemoryCacheConfig,
    createHttpLink,
    from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getToken } from '../lib/sessionStorage'
import { Person } from './person/resolvers'
import { enqueueError } from '../lib/snackbar'

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_APOLLO_BACKEND_URL}`,
})

const authLink = setContext((_, { headers }) => {
    const token = getToken()
    return {
        headers: {
            ...headers,
            authorization: token,
        },
    }
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError?.message.includes('Received status code 403'))
        window.location.href = '/login'

    if (!graphQLErrors) return
    for (const err of graphQLErrors) {
        enqueueError(err)
    }
})

const cacheOptions: InMemoryCacheConfig = {
    typePolicies: {
        Person,
    },
}

const cache = new InMemoryCache(cacheOptions)

export const client = new ApolloClient({
    connectToDevTools: true,
    link: from([errorLink, authLink, httpLink]),
    cache,
})

export const getCache = () => cache
