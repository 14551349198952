import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from '../../../lib/dayjs'


export type ComponentProps = {
    value: Date | undefined
    label?: string
    onChange: (newValue: Date | undefined) => void
}

const DateField = ({ value, onChange, label }: ComponentProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value ? dayjs(value) : null}
                sx={{ width: '100%' }}
                slotProps={{
                    textField: { size: 'small' },
                    actionBar: { actions: ['clear'] },
                }}
                format="DD/MM/YYYY"
                label={label || 'Date from'}
                onChange={(newValue) =>
                    onChange(newValue ? dayjs(newValue).toDate() : undefined)
                }
            />
        </LocalizationProvider>
    )
}
export default React.memo(DateField)
