import { Route, Routes, createBrowserRouter } from 'react-router-dom'
import PublicScreen from '../screens/PublicScreen'
import Login from '../components/login'
import DashboardScreen from '../screens/DashboardScreen'
import BaseScreen from '../screens/BaseScreen'
import CategoriesScreen from '../screens/CategoriesScreen'
import PeopleScreen from '../screens/PeopleScreen'
import Therapists from '../components/people/therapists'
import PeopleTable from '../components/people/people/peopleTable/PeopleTable'
import UpdateTherapist from '../components/people/therapists/updateTherapist'
import NewTherapist from '../components/people/therapists/newTherapist/NewTherapist'
import Customers from '../components/people/customers'
import NewCustomer from '../components/people/customers/newCustomer/NewCustomer'
import UpdateCustomer from '../components/people/customers/updateCustomer'
import NewPerson from '../components/people/people/newPerson/NewPerson'
import UpdatePerson from '../components/people/people/updatePerson/UpdatePerson'
import SessionScreen from '../screens/SessionScreen'
import Sessions from '../components/sessions'
import NewSession from '../components/sessions/newSession'
import UpdateSession from '../components/sessions/updateSession'
import AccountScreen from '../screens/AccountScreen'
import Accounts from '../components/account'
import NewAccount from '../components/account/newAccount'
import UpdateAccount from '../components/account/updateAccount'
import PaymentMethods from '../components/paymentMethod'
import NewPaymentMethod from '../components/paymentMethod/newPaymentMethod/NewPaymentMethod'
import UpdatePaymentMethod from '../components/paymentMethod/updatePaymentMethod/UpdatePaymentMethod'
import PaymentMethodScreen from '../screens/PaymentMethodScreen'
import PayOrder from '../components/order/payOrder/PayOrder'
import NewCategory from '../components/category/newCategory/NewCategory'
import UpdateCategory from '../components/category/updateCategory/UpdateCategory'
import Categories from '../components/category'
import SuppliersScreen from '../screens/SuppliersScreen'
import Suppliers from '../components/suppliers'
import NewSupplier from '../components/suppliers/newSupplier'
import UpdateSupplier from '../components/suppliers/updateSupplier'
import PaySupplier from '../components/suppliers/paySupplier/PaySupplier'
import TransactionsScreen from '../screens/TransactionsScreen'
import Transactions from '../components/transactions'
import ActivitiesScreen from '../screens/ActivitiesScreen'
import Activities from '../components/activity'
import NewActivity from '../components/activity/newActivity'
import UpdateActivity from '../components/activity/updateActivity'
import Organizers from '../components/people/organizers'
import NewOrganizer from '../components/people/organizers/newOrganizer'
import UpdateOrganizer from '../components/people/organizers/updateOrganizer'
import ArticlesScreen from '../screens/ArticlesScreen'
import Articles from '../components/article'
import NewArticle from '../components/article/newArticle'
import UpdateArticle from '../components/article/updateArticle'
import OrdersScreen from '../screens/OrdersScreen'
import Orders from '../components/order'
import NewOrder from '../components/order/newOrder'
import Invoice from '../components/invoice'
import UpdateOrder from '../components/order/updateOrder'
import ViewOrder from '../components/order/viewOrder'
import ViewTherapist from '../components/people/therapists/viewTherapist'
import ViewCustomer from '../components/people/customers/viewCustomer'
import Dashboard from '../components/dashboard'
import SessionsCalendar from '../components/sessions/sessionsCalendar'

const PrivateRouter = () => {
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="categories" element={<CategoriesScreen />}>
                <Route index element={<Categories />} />
                <Route path="new" element={<NewCategory />} />
                <Route path="update/:id" element={<UpdateCategory />} />
            </Route>
            <Route path="people" element={<PeopleScreen />}>
                <Route index element={<PeopleTable />} />
                <Route path="new" element={<NewPerson />} />
                <Route path="update/:id" element={<UpdatePerson />} />
                <Route path="therapists">
                    <Route index element={<Therapists />} />
                    <Route path="new" element={<NewTherapist />} />
                    <Route path="update/:id" element={<UpdateTherapist />} />
                    <Route path=":id" element={<ViewTherapist />} />
                </Route>
                <Route path="customers">
                    <Route index element={<Customers />} />
                    <Route path="new" element={<NewCustomer />} />
                    <Route path="update/:id" element={<UpdateCustomer />} />
                    <Route path=":id" element={<ViewCustomer />} />
                </Route>
                <Route path="organizers">
                    <Route index element={<Organizers />} />
                    <Route path="new" element={<NewOrganizer />} />
                    <Route path="update/:id" element={<UpdateOrganizer />} />
                </Route>
            </Route>
            <Route path="sessions" element={<SessionScreen />}>
                <Route index element={<Sessions />} />
                <Route path="new" element={<NewSession />} />
                <Route path="update/:id" element={<UpdateSession />} />
                <Route path="calendar" element={<SessionsCalendar />} />
            </Route>
            <Route path="accounts" element={<AccountScreen />}>
                <Route index element={<Accounts />} />
                <Route path="new" element={<NewAccount />} />
                <Route path="update/:id" element={<UpdateAccount />} />
            </Route>
            <Route path="articles" element={<ArticlesScreen />}>
                <Route index element={<Articles />} />
                <Route path="new" element={<NewArticle />} />
                <Route path="update/:id" element={<UpdateArticle />} />
            </Route>
            <Route path="orders" element={<OrdersScreen />}>
                <Route index element={<Orders />} />
                <Route path="new" element={<NewOrder />} />
                <Route path="new/:orderType/:id" element={<NewOrder />} />
                <Route path="update/:id" element={<UpdateOrder />} />
                <Route path="pay/:id" element={<PayOrder />} />
                <Route path=":id" element={<ViewOrder />} />
            </Route>
            <Route path="payment-methods" element={<PaymentMethodScreen />}>
                <Route index element={<PaymentMethods />} />
                <Route path="new" element={<NewPaymentMethod />} />
                <Route path="update/:id" element={<UpdatePaymentMethod />} />
            </Route>
            <Route path="suppliers" element={<SuppliersScreen />}>
                <Route index element={<Suppliers />} />
                <Route path="new" element={<NewSupplier />} />
                <Route path="update/:id" element={<UpdateSupplier />} />
                <Route path=":id/payment" element={<PaySupplier />} />
            </Route>
            <Route path="activities" element={<ActivitiesScreen />}>
                <Route index element={<Activities />} />
                <Route path="new" element={<NewActivity />} />
                <Route path="update/:id" element={<UpdateActivity />} />
            </Route>
            <Route path="transactions" element={<TransactionsScreen />}>
                <Route index element={<Transactions />} />
                <Route path="invoice/:id" element={<Invoice />} />
            </Route>
        </Routes>
    )
}

const PublicRouter = () => {
    return (
        <PublicScreen>
            <Routes>
                <Route index element={<Login />} />
            </Routes>
        </PublicScreen>
    )
}

export const rootRouter = createBrowserRouter([
    {
        path: 'login/*',
        element: <PublicRouter />,
    },
    {
        path: '*',
        element: (
            <BaseScreen>
                <DashboardScreen>
                    <PrivateRouter />
                </DashboardScreen>
            </BaseScreen>
        ),
    },
])
