import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Category,
    useGetCategoryQuery,
    useUpdateCategoryMutation,
} from '../../../generated/graphql'
import CategoryForm from '../categoryForm'
import Loading from '../../loading'

const UpdateCategory = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetCategoryQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateCategory, { loading: loadingUpdate }] =
        useUpdateCategoryMutation()

    const submit = React.useCallback(
        (category: Category) => {
            updateCategory({
                variables: {
                    data: {
                        id: category.id,
                        name: category.name,
                    },
                },
                onCompleted() {
                    navigate('/categories')
                },
                refetchQueries: ['getCategories'],
            })
        },
        [navigate, updateCategory]
    )

    const categoryForm = React.useMemo(() => {
        return data?.category ? (
            <CategoryForm category={data?.category} submit={submit} />
        ) : null
    }, [data?.category, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : categoryForm
}

export default React.memo(UpdateCategory)
