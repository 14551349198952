import React from 'react'
import { Category } from '../../../generated/graphql'
import { Button, Grid, TextField } from '@mui/material'

export type ComponentProps = {
    category: Category
    submit: (category: Category) => void
}

const CategoryForm = ({ category, submit }: ComponentProps) => {
    const [updatedCategory, setUpdatedCategory] =
        React.useState<Category>(category)
    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedCategory({
                ...updatedCategory,
                [name]: value,
            })
        },
        [updatedCategory, setUpdatedCategory]
    )
    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <TextField
                fullWidth
                value={updatedCategory.name}
                label="Category name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedCategory)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(CategoryForm)
