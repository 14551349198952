import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Activity,
    Category,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import ItemSelector from '../../selectors/ItemSelector'
import ActivityGroups from '../activityGroup/ActivityGroups'

export type ComponentProps = {
    activity: Activity
    submit: (activity: Activity) => void
}

const ActivityForm = ({ activity, submit }: ComponentProps) => {
    const [isUpdatingGroup, setIsUpdatingGroup] = React.useState<boolean>(false)
    const [updatedActivity, setUpdatedActivity] =
        React.useState<Activity>(activity)

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery({})

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedActivity({
                ...updatedActivity,
                [name]: value,
            })
        },
        [updatedActivity, setUpdatedActivity]
    )
    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <TextField
                fullWidth
                value={updatedActivity.name}
                label="Activity name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
            />
            <ItemSelector<Category>
                fullWidth
                data={categoriesData?.categories || []}
                selectorKey="category"
                loading={loadingCategories}
                selectedItem={updatedActivity.defaultCategory}
                setSelectedItem={(value: Category | undefined) =>
                    setUpdatedActivity({
                        ...updatedActivity,
                        defaultCategory: value,
                    })
                }
            />
            <ActivityGroups
                activityGroups={updatedActivity.groups || []}
                updateActivityGroups={(activityGroups) => {
                    setUpdatedActivity({
                        ...updatedActivity,
                        groups: activityGroups,
                    })
                }}
                updatingGroup={setIsUpdatingGroup}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedActivity)}
                disabled={isUpdatingGroup}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(ActivityForm)
