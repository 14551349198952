import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Order,
    useGetOrderQuery,
    useUpdateOrderMutation,
} from '../../../generated/graphql'
import OrderForm from '../orderForm'
import Loading from '../../loading'

const UpdateOrder = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetOrderQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateOrder, { loading: loadingUpdate }] = useUpdateOrderMutation()

    const submit = React.useCallback(
        (order: Order) => {
            updateOrder({
                variables: {
                    data: {
                        id: order.id,
                        date: order.date,
                        customerId: order.customer?.id,
                        rows: (order.rows || []).map((row) => ({
                            amount: row.amount,
                            price: row.price || row.article?.price,
                            articleId: row.article?.id,
                            sessionId: row.session?.id,
                        })),
                    },
                },
                onCompleted() {
                    navigate(`/orders/pay/${order?.id}`)
                },
                refetchQueries: ['getOrders'],
            })
        },
        [navigate, updateOrder]
    )

    const orderForm = React.useMemo(() => {
        return data?.order ? (
            <OrderForm order={data?.order} submit={submit} />
        ) : null
    }, [data?.order, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : orderForm
}

export default React.memo(UpdateOrder)
