import { Grid, Typography } from '@mui/material'
import React from 'react'
import HeaderContainer from '../headerContainer'
type HeaderPercentagesProps = {
    label?: string
    percentOhana: number
    percentTherapist: number
    percentIRPF: number
}

const HeaderPercentages = ({
    label = 'Global',
    percentOhana,
    percentTherapist,
    percentIRPF,
}: HeaderPercentagesProps) => {
    return (
        <HeaderContainer grow={1}>
            <Grid
                display={'flex'}
                flexGrow={1}
                flexDirection={'column'}
                justifyContent={'space-between'}
                sx={{ height: '100%', paddingLeft: 2, paddingRight: 2 }}
            >
                <Typography variant="h5">{label} Ohana:</Typography>
                <Typography variant="h4" sx={{ textAlign: 'right' }}>
                    {percentOhana.toFixed(2)}€
                </Typography>
                <Typography variant="h5">{label} Therapist:</Typography>
                <Typography variant="h4" sx={{ textAlign: 'right' }}>
                    {percentTherapist.toFixed(2)}€
                </Typography>
                <Typography variant="h5">{label} IRPF:</Typography>
                <Typography variant="h4" sx={{ textAlign: 'right' }}>
                    {percentIRPF.toFixed(2)}€
                </Typography>
            </Grid>
        </HeaderContainer>
    )
}

export default React.memo(HeaderPercentages)
