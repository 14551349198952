import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import {
    ActivityGroup,
    Person,
    useGetOrganizersQuery,
} from '../../../../generated/graphql'
import TextField from '@mui/material/TextField'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { InputLabel, MenuItem, OutlinedInput, Paper } from '@mui/material'
import Loading from '../../../loading'

export type ComponentProps = {
    activityGroup: ActivityGroup
    submit: (activityGroup: ActivityGroup, isNewGroup: boolean) => void
    cancel: () => void
    isNewGroup?: boolean
}

const ActivityGroupForm = ({
    activityGroup,
    submit,
    cancel,
    isNewGroup,
}: ComponentProps) => {
    const { data: organizersData, loading: organizersLoading } =
        useGetOrganizersQuery()

    const [updatedOrganizers, setUpdatedOrganizers] = React.useState<string[]>(
        activityGroup.organizers?.map((o) => o.name || '') || []
    )

    const [updatedActivityGroup, setUpdatedActivityGroup] =
        React.useState<ActivityGroup>(activityGroup)

    const handleOrganizerChange = React.useCallback(
        (e: SelectChangeEvent<typeof updatedOrganizers>) => {
            setUpdatedOrganizers(
                typeof e.target.value === 'string'
                    ? e.target.value.split(',')
                    : e.target.value
            )
        },
        []
    )

    const handleAddGroup = React.useCallback(() => {
        const selected = updatedOrganizers.filter((o) => !!o)
        const newGroup: ActivityGroup = {
            ...updatedActivityGroup,
            organizers: organizersData?.organizers?.reduce<Person[]>(
                (acc, o) => {
                    if (o && selected.includes(o.name || '')) {
                        acc.push(o)
                    }
                    return acc
                },
                []
            ),
        }
        submit(newGroup, !!isNewGroup)
    }, [
        updatedActivityGroup,
        organizersData?.organizers,
        updatedOrganizers,
        submit,
        isNewGroup,
    ])

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 4,
            }}
        >
            <Typography variant="h5">Add new group</Typography>
            <TextField
                fullWidth
                value={updatedActivityGroup.name}
                label="Group name"
                type="text"
                name="name"
                onChange={(e) =>
                    setUpdatedActivityGroup({
                        ...updatedActivityGroup,
                        name: e.target.value,
                    })
                }
            />
            {organizersLoading ? (
                <Loading />
            ) : (
                <>
                    <InputLabel id="demo-multiple-checkbox-label">
                        Select organizers
                    </InputLabel>
                    <Select
                        id="organizer-selector"
                        multiple
                        value={updatedOrganizers}
                        onChange={(e) => handleOrganizerChange(e)}
                        input={
                            <OutlinedInput
                                id="select-multiple-chip"
                                label="Select organizers"
                            />
                        }
                    >
                        {(organizersData?.organizers || []).map(
                            (organizer: Person) => (
                                <MenuItem
                                    key={organizer.id}
                                    value={organizer.name}
                                >
                                    {organizer.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </>
            )}
            <Grid display={'flex'} flexDirection={'row'} gap={2}>
                <Button
                    sx={{ flexGrow: 1 }}
                    variant="contained"
                    onClick={cancel}
                >
                    Cancel
                </Button>
                <Button
                    sx={{ flexGrow: 1 }}
                    variant="contained"
                    onClick={handleAddGroup}
                >
                    {isNewGroup ? `Add group` : `save`}
                </Button>
            </Grid>
        </Paper>
    )
}

export default React.memo(ActivityGroupForm)
