import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Person,
    Session,
    useGetCustomersQuery,
    useGetTherapistsQuery,
} from '../../../generated/graphql'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import ItemSelector from '../../selectors/ItemSelector/ItemSelector'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'
import dayjs from '../../../lib/dayjs'

export type ComponentProps = {
    session: Session
    submit: (session: Session) => void
}

const SessionForm = ({ session, submit }: ComponentProps) => {
    const { data: therapistsData, loading: loadingTherapists } =
        useGetTherapistsQuery()
    const { data: customerData, loading: loadingCustomers } =
        useGetCustomersQuery()

    const [updatedSession, setUpdatedSession] = React.useState<Session>(session)

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedSession({
                ...updatedSession,
                [name]: value,
            })
        },
        [updatedSession, setUpdatedSession]
    )

    const handleDurationChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = e.target
            setUpdatedSession({
                ...updatedSession,
                duration: parseFloat(value),
            })
        },
        [updatedSession, setUpdatedSession]
    )

    const handleDatePickerChange = React.useCallback(
        (value: dayjs.Dayjs | null) => {
            if (!value) return
            setUpdatedSession({
                ...updatedSession,
                date: value.toDate(),
            })
        },
        [updatedSession]
    )

    return (
        <Grid container gap={3} flexDirection={'column'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                    label="Select the day and hour of the session"
                    ampm={false}
                    value={dayjs(updatedSession.date).utc()}
                    onChange={handleDatePickerChange}
                    format="DD/MM/YYYY HH:mm"
                />
            </LocalizationProvider>

            <ItemSelector
                selectorKey="therapist"
                data={
                    therapistsData?.therapists?.filter(
                        (person) => !person.therapist?.disabled
                    ) || []
                }
                selectedItem={updatedSession.therapist}
                loading={loadingTherapists}
                setSelectedItem={(therapist) => {
                    setUpdatedSession({ ...updatedSession, therapist })
                }}
            />
            <AutocompleteSelector<Person>
                data={
                    customerData?.customers?.map((c) => ({
                        label: `${c.fullName}`,
                        id: c.id,
                    })) || []
                }
                fullWidth
                selectedItemId={updatedSession?.customer?.id || undefined}
                selectorKey="customer"
                setSelectedItem={(item) => {
                    const customer = customerData?.customers?.find(
                        (p) => p.id === item?.id
                    )
                    setUpdatedSession({ ...updatedSession, customer })
                }}
                loading={loadingCustomers}
            />
            <TextField
                fullWidth
                value={updatedSession.duration}
                label="Duration (hours)"
                type="number"
                name="duration"
                onChange={(e) => handleDurationChange(e)}
            />

            <TextField
                fullWidth
                value={updatedSession.notes}
                label="Notes"
                type="text"
                name="notes"
                onChange={(e) => handleChange(e)}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedSession)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(SessionForm)
