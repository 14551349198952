import { Button, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    CreateTransactionPaymentMutation,
    Transaction,
    TransactionSupplier,
    TransactionType,
    useCreateTransactionPaymentMutation,
    useGetAccountsQuery,
    useGetPaymentMethodsQuery,
    useGetSupplierQuery,
} from '../../../generated/graphql'
import Loading from '../../loading'
import ItemSelector from '../../selectors/ItemSelector'
import DateField from '../../pickers/dateField'
import { addToQuery, evictQuery } from '../../../apollo/resolvers'

const PaySupplier = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [transaction, setTransaction] = React.useState<Transaction>({
        date: undefined,
        amount: 0,
        payment: undefined,
        paymentMethod: undefined,
        account: undefined,
        type: TransactionType.Expense,
    })

    const { data: supplierData, loading: supplierLoading } =
        useGetSupplierQuery({
            variables: {
                data: { id },
            },
            onCompleted(data) {
                setTransaction({
                    ...transaction,
                    payment: {
                        supplier: data.supplier as TransactionSupplier,
                    },
                })
            },
        })

    const { data: accountsData, loading: accountsLoading } =
        useGetAccountsQuery()
    const { data: paymentMethodsData, loading: paymentMethodsLoading } =
        useGetPaymentMethodsQuery()

    const supplier = React.useMemo(() => {
        return supplierData?.supplier
    }, [supplierData?.supplier])

    const [createTransaction, { loading: createTransactionLoading }] =
        useCreateTransactionPaymentMutation()

    const paySupplier = React.useCallback(() => {
        createTransaction({
            variables: {
                data: {
                    date: transaction.date,
                    amount: transaction.amount,
                    paymentMethodId: transaction.paymentMethod?.id,
                    supplierId: transaction.payment?.supplier?.id,
                    accountId: transaction.account?.id,
                    type: transaction.type,
                },
            },
            onCompleted(data: CreateTransactionPaymentMutation) {
                evictQuery('transactionsSearch')
                addToQuery(data.createTransactionPayment, 'transactions')
                navigate(-1)
            },
        })
    }, [transaction, createTransaction, navigate])

    const handleAmountChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setTransaction({
                ...transaction,
                [name]: parseFloat(value),
            })
        },
        [setTransaction, transaction]
    )

    return supplierLoading || createTransactionLoading ? (
        <Loading />
    ) : (
        <Grid>
            <Grid>
                <Typography>Supplier: {supplier?.name}</Typography>
                <Typography>Email: {supplier?.email}</Typography>
                <Typography>Phone: {supplier?.phone}</Typography>
                <Typography>NIF: {supplier?.nif} hours</Typography>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <Grid container gap={3} flexDirection={'column'} component="form">
                <DateField
                    value={transaction.date}
                    onChange={(newValue) =>
                        setTransaction({
                            ...transaction,
                            date: newValue,
                        })
                    }
                />

                <TextField
                    fullWidth
                    value={transaction.amount}
                    label="Price"
                    type="number"
                    name="amount"
                    inputProps={{
                        step: 'any',
                    }}
                    InputProps={{
                        endAdornment: '€',
                    }}
                    onChange={handleAmountChange}
                />

                <ItemSelector
                    selectorKey="paymentMethod"
                    data={paymentMethodsData?.paymentMethods || []}
                    selectedItem={transaction.paymentMethod}
                    loading={paymentMethodsLoading}
                    setSelectedItem={(paymentMethod) => {
                        setTransaction({
                            ...transaction,
                            paymentMethod,
                        })
                    }}
                />

                <ItemSelector
                    selectorKey="account"
                    data={accountsData?.accounts || []}
                    selectedItem={transaction.account}
                    loading={accountsLoading}
                    setSelectedItem={(account) => {
                        setTransaction({
                            ...transaction,
                            account,
                        })
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={paySupplier}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(PaySupplier)
