import React from 'react'
import { useLocation } from 'react-router-dom'

const useTitleLocation = () => {
    const location = useLocation()

    const title = React.useMemo(() => {
        const relativePath = location.pathname.replace('/', '')
        return relativePath
            ? relativePath.toUpperCase().replaceAll('/', ' > ')
            : 'DASHBOARD'
    }, [location])

    return {
        title,
    }
}

export default useTitleLocation
