import React from 'react'
import {
    useDeleteAccountMutation,
    useGetAccountsQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import DeleteButton from '../deleteButton'
import { DeleteItem } from '../deleteButton/DeleteButton'

const Accounts = () => {
    const navigate = useNavigate()
    const { data: accountsData, loading } = useGetAccountsQuery()

    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [deleteAccount, { loading: loadingDelete }] =
        useDeleteAccountMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteAccount({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getAccounts'],
            })
        },
        [deleteAccount, deleting, setDeleteting]
    )

    const accounts = React.useMemo(
        () => accountsData?.accounts || [],
        [accountsData]
    )

    return loading ? (
        <Loading />
    ) : (
        <List>
            {accounts.map((account) => (
                <ListItem
                    key={account.id}
                    divider
                    sx={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                    <ListItemText
                        primary={account.name}
                        secondary={account.accountNumber}
                    />
                    {loadingDelete && deleting.includes(account.id || '') ? (
                        <Loading />
                    ) : (
                        <ListItemSecondaryAction>
                            <DeleteButton
                                onDelete={handleDelete}
                                item={account as DeleteItem}
                            />
                            <IconButton
                                aria-label="info"
                                onClick={() => navigate(`update/${account.id}`)}
                            >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))}
        </List>
    )
}

export default React.memo(Accounts)
