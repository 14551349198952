import { AUTHENTICATE, AUTHORISED } from '../apollo/authentication/queries'
import { client } from '../apollo/client'
import {
    deleteSession,
    getGoogleToken,
    getToken,
    saveSession,
} from './sessionStorage'

export const googleAuthorized = async () => {
    const googleToken = getGoogleToken()
    if (!googleToken) {
        throw new Error('Authorization failed')
    }
    try {
        const gAuthenticated = await client.query({
            query: AUTHENTICATE,
            fetchPolicy: 'no-cache',
            variables: {
                data: {
                    google: {
                        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
                        credential: googleToken,
                    },
                },
            },
        })
        saveSession(gAuthenticated.data?.authenticate?.token || '', googleToken)
        return true
    } catch (e) {
        deleteSession()
        throw e
    }
}

export const authorized = async () => {
    if (!getToken()) {
        return googleAuthorized()
    }
    try {
        await client.query({
            query: AUTHORISED,
            fetchPolicy: 'no-cache',
            context: {
                headers: {
                    authorization: getToken() || '',
                },
            },
        })
        return true
    } catch (e) {
        return googleAuthorized()
    }
}
