import { CircularProgress, CircularProgressProps } from '@mui/material'
import React from 'react'

export type ComponentProps = {
    sx?: CircularProgressProps['sx']
    size?: CircularProgressProps['size']
}

const Loading = ({ sx, size }: ComponentProps) => {
    return <CircularProgress size={size} sx={sx} />
}

export default React.memo(Loading)
