import { Box, Button, Grid, IconButton, TextField } from '@mui/material'
import React from 'react'
import {
    useDeletePersonMutation,
    useGetPeopleQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import DeleteButton from '../../../deleteButton'
import CustomDataGrid from '../../../customDataGrid'

const PeopleTable = () => {
    const navigate = useNavigate()
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [search, setSearch] = React.useState('')

    const [deletePerson, { loading: loadingDelete }] = useDeletePersonMutation()

    const { data, loading } = useGetPeopleQuery()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deletePerson({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: (data) => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: [
                    'getPeople',
                    'getCustomers',
                    'getTherapists',
                    'getOrganizers',
                ],
            })
        },
        [deletePerson, deleting, setDeleteting]
    )

    const people = React.useMemo(() => {
        return data?.people || []
    }, [data])

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            { field: 'dni', headerName: 'DNI', flex: 1 },
            { field: 'customer', headerName: 'Is Customer', flex: 1 },
            { field: 'therapist', headerName: 'Is Therapist', flex: 1 },
            { field: 'organizer', headerName: 'Is Organizer', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 90,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [handleDelete, navigate, loadingDelete, deleting]
    )

    const rows = React.useMemo(() => {
        const searched = search
            ? people.filter(
                  (p) =>
                      (p.fullName?.toLocaleLowerCase() || '').includes(
                          search
                      ) ||
                      (p.email?.toLocaleLowerCase() || '').includes(search) ||
                      (p.phone || '').includes(search) ||
                      (p.surname?.toLocaleLowerCase() || '').includes(search) ||
                      (p.dni || '').includes(search)
              )
            : people
        return searched.map((person) => {
            return {
                id: person.id,
                name: person.fullName,
                email: person.email,
                phone: person.phone,
                dni: person.dni,
                customer: person.customer ? 'Yes' : 'No',
                therapist: person.therapist ? 'Yes' : 'No',
                organizer: person.organizer ? 'Yes' : 'No',
            }
        })
    }, [people, search])

    return loading ? (
        <Loading />
    ) : (
        <>
            <Grid
                display={'flex'}
                alignItems={'center'}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size={'small'}
                    fullWidth
                    onChange={(e) =>
                        setSearch((e.target.value || '').toLowerCase())
                    }
                />
                <Button variant="contained" sx={{ marginLeft: 2 }}>
                    seach
                </Button>
            </Grid>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
            />
        </>
    )
}
export default React.memo(PeopleTable)
