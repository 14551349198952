import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Account } from '../../../generated/graphql'

export type ComponentProps = {
    account: Account
    submit: (account: Account) => void
}

const AccountForm = ({ account, submit }: ComponentProps) => {
    const [updatedAccount, setUpdatedAccount] = React.useState<Account>(account)
    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedAccount({
                ...updatedAccount,
                [name]: value,
            })
        },
        [updatedAccount, setUpdatedAccount]
    )
    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <TextField
                fullWidth
                value={updatedAccount.name}
                label="Account name"
                type="text"
                name="name"
                required={true}
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedAccount.accountNumber}
                label="Account number"
                type="text"
                name="accountNumber"
                onChange={(e) => handleChange(e)}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedAccount)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(AccountForm)
