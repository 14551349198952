import React from 'react'
import { useGetGlobalIncomeExpenseQuery } from '../../../../generated/graphql'
import { Grid, MenuItem, Paper, Select, Typography } from '@mui/material'
import Loading from '../../../loading'
import { LineChart } from '@mui/x-charts/LineChart'
import dayjs from '../../../../lib/dayjs'


type ComponentProps = {
    width?: number | string
    height?: number
}

const GlobalIncomeExpense = ({ height = 250 }: ComponentProps) => {
    const [year, setYear] = React.useState(new Date().getFullYear())

    const { data: globalData, loading: globalLoading } =
        useGetGlobalIncomeExpenseQuery()

    const yearEntries = React.useMemo(() => {
        return (
            globalData?.globalIncomesAndExpenses?.filter(
                (e) => e.year === year.toString()
            ) || []
        )
    }, [globalData?.globalIncomesAndExpenses, year])

    const months = React.useMemo(
        () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        []
    )
    const years = React.useMemo(() => {
        const years = new Set<string>()
        globalData?.globalIncomesAndExpenses?.forEach((entry) => {
            if (entry.year) years.add(entry.year)
        })
        return Array.from(years)
    }, [globalData?.globalIncomesAndExpenses])

    const chart = React.useMemo(() => {
        if (globalLoading) return <Loading />

        const income = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
            const entry = yearEntries.find(
                (e) => e.month === (month + 1).toString()
            )
            return entry?.income || 0
        })

        const expense = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
            const entry = yearEntries.find(
                (e) => e.month === (month + 1).toString()
            )
            return entry?.expense || 0
        })

        const benefit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
            const entry = yearEntries.find(
                (e) => e.month === (month + 1).toString()
            )
            return entry?.benefit || 0
        })

        return (
            <LineChart
                sx={{ width: '100%' }}
                height={height}
                xAxis={[
                    {
                        label: 'Month',
                        scaleType: 'time',
                        data: months,
                        tickInterval: months,
                        valueFormatter: (month) => {
                            return dayjs(new Date(2020, month)).format('MMM')
                        },
                    },
                ]}
                series={[
                    {
                        data: income,
                        color: '#90caf9',
                    },
                    {
                        data: expense,
                        color: '#e57373',
                    },
                    {
                        data: benefit,
                        color: '#66bb6a',
                    },
                ]}
            />
        )
    }, [yearEntries, globalLoading, months, height])

    return (
        <Paper
            sx={{
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ width: '100%' }}
            >
                <Typography variant={'h5'}>
                    Global Income and Expense
                </Typography>
                <Select
                    value={year}
                    onChange={(e) => {
                        setYear(e.target.value as number)
                    }}
                    size={'small'}
                >
                    {years.map((year) => {
                        return (
                            <MenuItem
                                value={year}
                                key={`year-selector-${year}`}
                            >
                                {year}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            {chart}
        </Paper>
    )
}

export default React.memo(GlobalIncomeExpense)
