import React from 'react'
import {
    useDeleteSupplierMutation,
    useGetSuppliersQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import DeleteButton from '../deleteButton'
import CustomDataGrid from '../customDataGrid'

const Suppliers = () => {
    const navigate = useNavigate()
    const { data: suppliersData, loading } = useGetSuppliersQuery()

    const [search, setSearch] = React.useState('')
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [deleteSupplier, { loading: loadingDelete }] =
        useDeleteSupplierMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteSupplier({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getSuppliers'],
            })
        },
        [deleteSupplier, deleting, setDeleteting]
    )

    const suppliers = React.useMemo(
        () => suppliersData?.suppliers || [],
        [suppliersData]
    )

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'category', headerName: 'Category', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            { field: 'nif', headerName: 'NIF', flex: 1 },
            { field: 'website', headerName: 'Website', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => navigate(`${row.id}/payment`)}
                        >
                            <ShoppingCartCheckoutIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [handleDelete, navigate, deleting, loadingDelete]
    )

    const rows = React.useMemo(() => {
        const searched = search
            ? suppliers.filter(
                  (s) =>
                      (s.name?.toLocaleLowerCase() || '').includes(search) ||
                      (s.email?.toLocaleLowerCase() || '').includes(search) ||
                      (s.phone || '').includes(search) ||
                      (s.website?.toLocaleLowerCase() || '').includes(search) ||
                      (s.nif || '').includes(search)
              )
            : suppliers
        return searched.map((supplier) => {
            return {
                id: supplier.id,
                name: supplier.name,
                email: supplier.email,
                phone: supplier.phone,
                nif: supplier.nif,
                website: supplier.website,
                category: supplier.defaultCategory?.name,
            }
        })
    }, [suppliers, search])

    return loading ? (
        <Loading />
    ) : (
        <>
            <Grid
                display={'flex'}
                alignItems={'center'}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size={'small'}
                    fullWidth
                    onChange={(e) =>
                        setSearch((e.target.value || '').toLowerCase())
                    }
                />
                <Button variant="contained" sx={{ marginLeft: 2 }}>
                    seach
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                    onClick={() => navigate('new')}
                >
                    Add
                </Button>
            </Grid>
            <CustomDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
            />
        </>
    )
}

export default React.memo(Suppliers)
