import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Account,
    useGetAccountQuery,
    useUpdateAccountMutation,
} from '../../../generated/graphql'
import AccountForm from '../accountForm'
import Loading from '../../loading'

const UpdateAccount = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetAccountQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateAccount, { loading: loadingUpdate }] =
        useUpdateAccountMutation()

    const submit = React.useCallback(
        (account: Account) => {
            updateAccount({
                variables: {
                    data: {
                        id: account.id,
                        name: account.name,
                    },
                },
                onCompleted() {
                    navigate('/accounts')
                },
            })
        },
        [navigate, updateAccount]
    )

    const accountForm = React.useMemo(() => {
        return data?.account ? (
            <AccountForm account={data?.account} submit={submit} />
        ) : null
    }, [data?.account, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : accountForm
}

export default React.memo(UpdateAccount)
