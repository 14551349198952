import React from 'react'

import { Activity, useCreateActivityMutation } from '../../../generated/graphql'

import Loading from '../../loading'
import ActivityForm from '../activityForm'
import { useNavigate } from 'react-router-dom'

const NewActivity = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreateActivityMutation()

    const submit = React.useCallback(
        (activity: Activity) => {
            const activityGroups = activity.groups?.map((group) => ({
                id: group.id,
                name: group.name,
                organizerIds: group?.organizers?.reduce<string[]>((acc, o) => {
                    if (o?.id) {
                        acc.push(o.id)
                    }
                    return acc
                }, []),
            }))
            create({
                variables: {
                    data: {
                        name: activity.name,
                        defaultCategoryId: activity.defaultCategory?.id,
                        groups: activityGroups,
                    },
                },
                onCompleted() {
                    navigate(-1)
                },
                refetchQueries: ['getActivities'],
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <ActivityForm
            activity={{
                name: '',
                defaultCategory: undefined,
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewActivity)
