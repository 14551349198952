import { Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Category,
    Supplier,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import ItemSelector from '../../selectors/ItemSelector'

export type ComponentProps = {
    supplier: Supplier
    submit: (supplier: Supplier) => void
}

const SupplierForm = ({ supplier, submit }: ComponentProps) => {
    const [updatedSupplier, setUpdatedSupplier] =
        React.useState<Supplier>(supplier)

    const { data: categoriesData, loading: categoriesLoading } =
        useGetCategoriesQuery()

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedSupplier({
                ...updatedSupplier,
                [name]: value,
            })
        },
        [updatedSupplier, setUpdatedSupplier]
    )
    return (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <TextField
                fullWidth
                value={updatedSupplier.name}
                label="Supplier name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedSupplier.email}
                label="Supplier email"
                type="email"
                name="email"
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedSupplier.phone}
                label="Supplier phone"
                type="phone"
                name="phone"
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedSupplier.website}
                label="Supplier Website"
                type="website"
                name="website"
                onChange={(e) => handleChange(e)}
            />
            <TextField
                fullWidth
                value={updatedSupplier.nif}
                label="Supplier nif"
                type="nif"
                name="nif"
                onChange={(e) => handleChange(e)}
            />
            <ItemSelector<Category>
                selectorKey="category"
                data={categoriesData?.categories || []}
                selectedItem={updatedSupplier?.defaultCategory}
                loading={categoriesLoading}
                setSelectedItem={(category) => {
                    setUpdatedSupplier({
                        ...updatedSupplier,
                        defaultCategory: category,
                    })
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedSupplier)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(SupplierForm)
