import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Supplier,
    useGetSupplierQuery,
    useUpdateSupplierMutation,
} from '../../../generated/graphql'
import SupplierForm from '../supplierForm'
import Loading from '../../loading'

const UpdateSupplier = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetSupplierQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateSupplier, { loading: loadingUpdate }] =
        useUpdateSupplierMutation()

    const submit = React.useCallback(
        (supplier: Supplier) => {
            updateSupplier({
                variables: {
                    data: {
                        id: supplier.id,
                        name: supplier.name,
                        phone: supplier.phone,
                        email: supplier.email,
                        website: supplier.website,
                        nif: supplier.nif,
                        defaultCategoryId: supplier.defaultCategory?.id,
                    },
                },
                onCompleted() {
                    navigate('/suppliers')
                },
                refetchQueries: ['getSuppliers'],
            })
        },
        [navigate, updateSupplier]
    )

    const supplierForm = React.useMemo(() => {
        return data?.supplier ? (
            <SupplierForm supplier={data?.supplier} submit={submit} />
        ) : null
    }, [data?.supplier, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : supplierForm
}

export default React.memo(UpdateSupplier)
