import React from 'react'

import {
    Category,
    CreateCategoryMutation,
    useCreateCategoryMutation,
} from '../../../generated/graphql'

import Loading from '../../loading'
import CategoryForm from '../categoryForm'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../apollo/resolvers'

const NewCategory = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreateCategoryMutation()

    const submit = React.useCallback(
        (category: Category) => {
            create({
                variables: {
                    data: category,
                },
                onCompleted(data: CreateCategoryMutation) {
                    addToQuery(data.createCategory, 'categories')
                    navigate(-1)
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <CategoryForm
            category={{
                name: '',
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewCategory)
