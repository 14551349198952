import React from 'react'
import Grid from '@mui/material/Grid'
import GlobalIncomExpense from './charts/globalIncomeExpense'

const Dashboard = () => {
    return (
        <Grid
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
        >
            <Grid sx={{ width: '45%' }}>
                <GlobalIncomExpense />
            </Grid>
        </Grid>
    )
}

export default React.memo(Dashboard)
