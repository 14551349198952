import { Transaction } from '../generated/graphql'

export const transactionOrderCategories = (
    transaction: Transaction | undefined
) => {
    return (
        transaction?.payment?.category?.name ||
        (transaction?.order?.rows || [])
            .map((o) => o.category?.name)
            .join(', ') ||
        transaction?.contribution?.category?.name
    )
}
