import React from 'react'
import { authorized } from '../lib/authorization'

const useAuthrorization = () => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)

    React.useEffect(() => {
        setLoading(true)
        authorized()
            .then(() => setError(false))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, [])

    return {
        loading,
        error,
    }
}

export default useAuthrorization
