import React from 'react'
import {
    useDeleteActivityMutation,
    useGetActivitiesQuery,
} from '../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import Loading from '../loading'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material'

const Activity = () => {
    const navigate = useNavigate()

    const { data: activitiesData, loading: activitiesLoading } =
        useGetActivitiesQuery()

    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [deleteActivity] = useDeleteActivityMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteActivity({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getActivities'],
            })
        },
        [deleteActivity, deleting, setDeleteting]
    )

    const activities = React.useMemo(
        () => activitiesData?.activities || [],
        [activitiesData]
    )

    return activitiesLoading ? (
        <Loading />
    ) : (
        <Grid display={'flex'} flexDirection={'column'}>
            {activities.map((activity) => {
                return (
                    <Box key={`Activity-${activity.id}`} sx={{ marginTop: 2 }}>
                        <Grid
                            display={'flex'}
                            justifyContent={'space-between'}
                            sx={{ marginBottom: 2 }}
                        >
                            <Box>
                                <Typography variant={'h4'}>
                                    {activity.name}
                                </Typography>
                                <Typography variant={'h5'}>
                                    Default Category:{' '}
                                    {activity.defaultCategory?.name || ''}
                                </Typography>
                            </Box>
                            <Grid>
                                <IconButton
                                    aria-label="info"
                                    onClick={() =>
                                        navigate(`update/${activity.id}`)
                                    }
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="info"
                                    onClick={() => handleDelete(activity.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid display={'flex'} gap={2} sx={{ marginBottom: 2 }}>
                            {activity.groups?.map((group) => {
                                return (
                                    <Card key={`ActivityGroup-${group.id}`}>
                                        <CardHeader title={group.name} />
                                        <CardContent>
                                            {group.organizers?.map(
                                                (organizer) => {
                                                    return (
                                                        <Typography
                                                            key={`ActivityGroupOrganizer-${organizer.id}`}
                                                        >
                                                            {organizer.name}
                                                        </Typography>
                                                    )
                                                }
                                            )}
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </Grid>
                        <Divider />
                    </Box>
                )
            })}
        </Grid>
    )
}

export default React.memo(Activity)
