import React from 'react'

import {
    Account,
    CreateAccountMutation,
    useCreateAccountMutation,
} from '../../../generated/graphql'

import Loading from '../../loading'
import AccountForm from '../accountForm/AccountForm'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../apollo/resolvers'

const NewAccount = () => {
    const navigate = useNavigate()

    const [create, { loading }] = useCreateAccountMutation()

    const submit = React.useCallback(
        async (account: Account) => {
            create({
                variables: {
                    data: account,
                },
                onCompleted(data: CreateAccountMutation) {
                    addToQuery(data.createAccount, 'accounts')
                    navigate(-1)
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <AccountForm
            account={{
                name: '',
                accountNumber: '',
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewAccount)
