import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Activity,
    useGetActivityQuery,
    useUpdateActivityMutation,
} from '../../../generated/graphql'
import ActivityForm from '../activityForm'
import Loading from '../../loading'

const UpdateActivity = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetActivity } = useGetActivityQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateActivity, { loading: loadingUpdate }] =
        useUpdateActivityMutation()

    const submit = React.useCallback(
        (activity: Activity) => {
            const activityGroups = activity.groups?.map((group) => ({
                id: group.id,
                name: group.name,
                organizerIds: group?.organizers?.reduce<string[]>((acc, o) => {
                    if (o?.id) {
                        acc.push(o.id)
                    }
                    return acc
                }, []),
            }))

            updateActivity({
                variables: {
                    data: {
                        id: activity.id,
                        name: activity.name,
                        defaultCategoryId: activity.defaultCategory?.id,
                        groups: activityGroups,
                    },
                },
                onCompleted() {
                    navigate('/activities')
                },
                refetchQueries: ['getActivities'],
            })
        },
        [navigate, updateActivity]
    )

    const activityForm = React.useMemo(() => {
        return data?.activity ? (
            <ActivityForm activity={data?.activity} submit={submit} />
        ) : null
    }, [data?.activity, submit])

    return loadingGetActivity || loadingUpdate ? <Loading /> : activityForm
}

export default React.memo(UpdateActivity)
