import React from 'react'
import PersonForm from '../personForm'
import {
    CreatePersonMutation,
    Person,
    useCreatePersonMutation,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { useNavigate } from 'react-router-dom'
import { addToQuery } from '../../../../apollo/resolvers'

const NewPerson = () => {
    const navigate = useNavigate()
    const [create, { loading }] = useCreatePersonMutation()

    const submit = React.useCallback(
        (person: Person) => {
            create({
                variables: {
                    data: {
                        name: person.name,
                        surname: person.surname,
                        email: person.email,
                        phone: person.phone,
                        dni: person.dni,
                    },
                },
                onCompleted: (data: CreatePersonMutation) => {
                    addToQuery(data.createPerson, 'people')
                    navigate('/people')
                },
            })
        },
        [create, navigate]
    )

    return loading ? (
        <Loading />
    ) : (
        <PersonForm
            person={{
                name: '',
                email: '',
                phone: '',
                dni: '',
            }}
            submit={submit}
        />
    )
}

export default React.memo(NewPerson)
