import Grid from '@mui/material/Grid'
import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ActionsBar from '../../components/actionsBar/ActionsBar'

const AccountScreen = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Grid flexDirection={'column'}>
            {location.pathname.includes('/update') ||
            location.pathname.includes('/new') ? null : (
                <ActionsBar
                    addClick={() => navigate('new')}
                    searchChange={() => {}}
                />
            )}
            <Outlet />
        </Grid>
    )
}

export default React.memo(AccountScreen)
