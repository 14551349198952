import React from 'react'
import { useGetOrderQuery } from '../../../generated/graphql'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

import { useParams } from 'react-router-dom'
import Loading from '../../loading'
import { rowPrice } from '../../../lib/Order'
import dayjs from '../../../lib/dayjs'
import CustomDataGrid from '../../customDataGrid'

const ViewOrder = () => {
    const { id } = useParams()

    const { data, loading } = useGetOrderQuery({
        variables: {
            data: {
                id,
            },
        },
    })
    const order = React.useMemo(() => {
        if (!data) return null
        return data.order
    }, [data])

    const orderRowColumns = React.useMemo(() => {
        return [
            { headerName: 'Row', field: 'id', flex: 1 },
            { headerName: 'Article name', field: 'article', flex: 1 },
            { headerName: 'Article price', field: 'price', flex: 1 },
            { headerName: 'Article amount', field: 'amount', flex: 1 },
            { headerName: 'Total', field: 'total', flex: 1 },
        ]
    }, [])

    const orderRowRows = React.useMemo(() => {
        return (order?.rows || []).map((row) => {
            const { price, amount, total } = rowPrice(row)

            return {
                id: row.article?.id || row.session?.id,
                article: row.article?.name || row.session?.therapist?.name,
                price,
                amount,
                total,
            }
        })
    }, [order])

    return loading ? (
        <Loading />
    ) : (
        <Grid container gap={3} flexDirection={'column'} component="form">
            <Grid sx={{ display: 'flex', gap: 2 }}>
                <Typography>
                    {order?.customer?.name} {order?.customer?.surname}
                </Typography>
                <Typography>
                    {dayjs(order?.date).utc().format('DD/MM/YYYY')}
                </Typography>
            </Grid>

            <CustomDataGrid
                rows={orderRowRows}
                columns={orderRowColumns}
                density="compact"
            />
        </Grid>
    )
}

export default React.memo(ViewOrder)
