import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import React from 'react'

export type ComponentProps = {
    searchChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    addClick: () => void
}

const ActionsBar = ({ searchChange, addClick }: ComponentProps) => {
    return (
        <Grid
            item
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <TextField
                fullWidth
                id="search"
                label="Search"
                variant="outlined"
                onChange={(e) => searchChange(e)}
            />
            <Grid id="actions">
                <Button
                    variant="contained"
                    onClick={(e) => addClick()}
                    sx={{ marginLeft: 4 }}
                >
                    Add
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(ActionsBar)
