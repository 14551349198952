import { Grid, Paper, SxProps, Theme } from '@mui/material'
import React from 'react'

type HeaderContainerProps = {
    grow: number
    children: React.ReactNode
    sx?: SxProps<Theme> | undefined
}

const HeaderContainer = ({ grow, children, sx }: HeaderContainerProps) => {
    return (
        <Grid item flexGrow={grow}>
            <Paper elevation={3} sx={{ padding: 2, height: '100%', ...sx }}>
                {children}
            </Paper>
        </Grid>
    )
}

export default React.memo(HeaderContainer)
