import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Article,
    useGetArticleQuery,
    useUpdateArticleMutation,
} from '../../../generated/graphql'
import ArticleForm from '../articleForm'
import Loading from '../../loading'

const UpdateArticle = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data, loading: loadingGetCustomer } = useGetArticleQuery({
        variables: {
            data: {
                id,
            },
        },
    })

    const [updateArticle, { loading: loadingUpdate }] =
        useUpdateArticleMutation()

    const submit = React.useCallback(
        (article: Article) => {
            updateArticle({
                variables: {
                    data: {
                        id: article.id,
                        name: article.name,
                        price: article.price,
                        defaultCategoryId: article.defaultCategory?.id,
                    },
                },
                onCompleted() {
                    navigate('/articles')
                },
                refetchQueries: ['getArticles'],
            })
        },
        [navigate, updateArticle]
    )

    const articleForm = React.useMemo(() => {
        return data?.article ? (
            <ArticleForm article={data?.article} submit={submit} />
        ) : null
    }, [data?.article, submit])

    return loadingGetCustomer || loadingUpdate ? <Loading /> : articleForm
}

export default React.memo(UpdateArticle)
