import React from 'react'
import { ActivityGroup } from '../../../generated/graphql'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ActivityGroupForm from './activityGroupForm'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {
    Box,
    Divider,
    IconButton,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { cloneDeep, isEmpty } from 'lodash'

export type ComponentProps = {
    activityGroups: ActivityGroup[]
    updateActivityGroups: (activityGroups: ActivityGroup[]) => void
    updatingGroup: (isUpdating: boolean) => void
}

const ActivityGroups = ({
    activityGroups,
    updateActivityGroups,
    updatingGroup,
}: ComponentProps) => {
    const [newGroup, setNewGroup] = React.useState<ActivityGroup | undefined>()
    const [editedGroup, setEditedGroup] = React.useState<
        ActivityGroup | undefined
    >()

    const handleNewGroup = React.useCallback(() => {
        setNewGroup({
            id: uuidv4(),
            name: '',
            organizers: [],
        })
        updatingGroup(true)
    }, [updatingGroup, setNewGroup])

    const addNewGroup = React.useCallback(
        (group: ActivityGroup) => {
            const newGroups = [...activityGroups, group]
            updateActivityGroups(newGroups)
            setNewGroup(undefined)
            updatingGroup(false)
        },
        [activityGroups, updateActivityGroups, updatingGroup]
    )

    const handleEditGroup = React.useCallback(
        (group: ActivityGroup) => {
            setEditedGroup(group)
            updatingGroup(true)
        },
        [updatingGroup, setEditedGroup]
    )

    const updateEditedGroup = React.useCallback(
        (group: ActivityGroup) => {
            const groups = cloneDeep(activityGroups)
            const edited = groups.find((g) => g.id === group?.id)
            if (edited) {
                edited.name = group?.name || ''
                edited.organizers = group?.organizers || []
            }

            updateActivityGroups(groups)
            setEditedGroup(undefined)
            updatingGroup(false)
        },
        [activityGroups, updateActivityGroups, updatingGroup]
    )

    const formGroup = React.useMemo(() => {
        if (newGroup) return newGroup
        else return editedGroup
    }, [newGroup, editedGroup])

    const handleSubmit = React.useCallback(
        (group: ActivityGroup) => {
            if (newGroup) {
                addNewGroup(group)
            } else {
                updateEditedGroup(group)
            }
        },
        [newGroup, addNewGroup, updateEditedGroup]
    )

    const handleDeleteGroup = React.useCallback(
        (group: ActivityGroup) => {
            const newGroups = activityGroups.filter((g) => g.id !== group.id)
            updateActivityGroups(newGroups)
        },
        [activityGroups, updateActivityGroups]
    )

    return (
        <>
            <Grid display={'flex'} justifyContent={'space-between'}>
                <Typography>Activity Groups</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewGroup}
                >
                    Add Group
                </Button>
            </Grid>
            {formGroup && (
                <ActivityGroupForm
                    activityGroup={formGroup}
                    submit={handleSubmit}
                    cancel={() => {
                        setEditedGroup(undefined)
                        setNewGroup(undefined)
                        updatingGroup(false)
                    }}
                    isNewGroup={!!newGroup && !editedGroup}
                />
            )}
            {!isEmpty(activityGroups) && (
                <Paper sx={{ padding: 2 }}>
                    <List>
                        {activityGroups
                            .filter((group) => group.id !== editedGroup?.id)
                            .map((group) => {
                                return (
                                    <Box key={`ActivityGroup-${group.id}`}>
                                        <ListItem key={group.id}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h5">
                                                        {' '}
                                                        {group.name}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography variant="body1">
                                                        Ogranized by:{' '}
                                                        {group.organizers
                                                            ?.map((o) => o.name)
                                                            .join(',')}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    aria-label="info"
                                                    disabled={
                                                        !!editedGroup ||
                                                        !!newGroup
                                                    }
                                                    onClick={() =>
                                                        handleEditGroup(group)
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={
                                                        !!editedGroup ||
                                                        !!newGroup
                                                    }
                                                    aria-label="info"
                                                    onClick={() =>
                                                        handleDeleteGroup(group)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </Box>
                                )
                            })}
                    </List>
                </Paper>
            )}
        </>
    )
}

export default React.memo(ActivityGroups)
