import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Loading from '../../loading'

export type ComponentProps<T> = {
    selectorKey: string
    loading?: boolean
    fullWidth?: boolean
    data: T[]
    selectedItem: T | undefined
    setSelectedItem: (item: T | undefined) => void
}

const ItemSelector = <
    T extends {
        id?: string | undefined
        name?: string | undefined
        fullName?: string | undefined
    }
>({
    selectorKey,
    data,
    selectedItem,
    setSelectedItem,
    loading,
    fullWidth,
}: ComponentProps<T>) => {
    return loading ? (
        <Loading />
    ) : (
        <>
            <Select
                size="small"
                fullWidth={fullWidth}
                labelId={`${selectorKey}-selector"`}
                id={`${selectorKey}-selector"`}
                value={selectedItem?.id || 'empty'}
                onChange={(event) => {
                    setSelectedItem(
                        data.find((item) => item.id === event.target.value)
                    )
                }}
            >
                <MenuItem value={'empty'}>{`Select ${selectorKey}`}</MenuItem>
                {data?.map((item) => (
                    <MenuItem
                        key={`menuItemKey-${selectorKey}-${item.id}`}
                        value={item.id}
                    >
                        {item.fullName || item.name}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}

export default ItemSelector
