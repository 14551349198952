import React from 'react'
import { Grid, Button, Typography, Paper } from '@mui/material'
import {
    DateRange,
    Session,
    useSearchSessionsQuery,
} from '../../../generated/graphql'
import Loading from '../../loading'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { EventSourceInput } from '@fullcalendar/core'
import { useNavigate } from 'react-router-dom'
import dayjs from '../../../lib/dayjs'
import SessionActionButtons from '../sessionActionButtons'

const SessionsCalendar = () => {
    const navigate = useNavigate()
    const calendarRef = React.useRef<FullCalendar>(null)

    const [dateRange, setDateRange] = React.useState<DateRange>({
        from: dayjs().day(1).toISOString(),
        to: dayjs().day(7).toISOString(),
    })

    const {
        data: sessionData,
        loading,
        refetch,
    } = useSearchSessionsQuery({
        variables: {
            data: {
                filters: {
                    dateRange,
                },
            },
        },
    })

    React.useEffect(() => {
        if (!calendarRef.current) return

        const api = calendarRef.current.getApi()
        api.gotoDate(dateRange.from)
    }, [sessionData, dateRange.from])

    const renderEventContent = React.useCallback(
        (eventInfo: any) => {
            const session = JSON.parse(eventInfo.event.title) as Session
            const date = new Date(session.date)
            const options = {
                timeZone: 'Europe/Madrid',
                hour12: false,
                second: undefined,
            }
            const spanishDate = date.toLocaleTimeString('es-ES', options)
            const finalString = spanishDate.substring(
                0,
                spanishDate.lastIndexOf(':')
            )
            return (
                <Paper sx={{ width: '100%', padding: 1, cursor: 'pointer' }}>
                    <Grid
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Typography sx={{ width: '100%' }}>
                            <Grid
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <b>{finalString}</b>
                                <SessionActionButtons
                                    session={session}
                                    refetch={refetch}
                                />
                            </Grid>
                        </Typography>
                        <Typography>
                            {session?.therapist?.name}{' '}
                            {session.therapist?.surname}
                        </Typography>
                        <Typography>
                            {session.customer
                                ? `${session.customer?.name} ${session.customer?.surname}`
                                : session.notes}
                        </Typography>
                    </Grid>
                </Paper>
            )
        },
        [refetch]
    )

    const events = React.useMemo((): EventSourceInput => {
        return (
            sessionData?.searchSessions?.sessions?.map((session) => {
                return {
                    title: JSON.stringify(session),
                    start: dayjs(session.date).toISOString(),
                    source: session,
                }
            }) || []
        )
    }, [sessionData?.searchSessions])

    const moveWeek = React.useCallback(
        (weekMovement: number) => {
            const start = dayjs(dateRange.from)
                .add(weekMovement, 'week')
                .toISOString()

            setDateRange({
                from: start,
                to: dayjs(start).endOf('week').add(1, 'day').toISOString(),
            })
        },
        [setDateRange, dateRange.from]
    )

    return loading ? (
        <Loading />
    ) : (
        <Grid>
            <Grid
                display={'flex'}
                flexGrow={1}
                flexDirection={'row'}
                justifyContent={'space-between'}
                sx={{ marginBottom: 2 }}
            >
                <Grid display={'flex'} gap={2}>
                    <Button variant={'contained'} onClick={() => moveWeek(-1)}>
                        previous
                    </Button>
                    <Button variant={'contained'} onClick={() => moveWeek(1)}>
                        next
                    </Button>
                </Grid>
                <Grid>
                    <Typography variant={'h4'}>
                        {dayjs(dateRange.from).format('MMMM YYYY')}
                    </Typography>
                </Grid>
                <Grid>
                    <Button
                        variant={'contained'}
                        onClick={() => navigate('/sessions/new')}
                    >
                        new
                    </Button>
                </Grid>
            </Grid>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin]}
                initialView="dayGridWeek"
                dayHeaderFormat={{
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    omitCommas: false,
                }}
                firstDay={1}
                headerToolbar={false}
                weekends={true}
                events={events}
                eventContent={renderEventContent}
                initialDate={dateRange.from}
            />
        </Grid>
    )
}

export default React.memo(SessionsCalendar)
