import { Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { PaymentMethod } from '../../../generated/graphql'

export type ComponentProps = {
    paymentMethod: PaymentMethod
    submit: (account: PaymentMethod) => void
}

const PaymentMethodForm = ({ paymentMethod, submit }: ComponentProps) => {
    const [updatedPaymentMethod, setUpdatedPaymentMethod] =
        React.useState<PaymentMethod>(paymentMethod)

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedPaymentMethod({
                ...updatedPaymentMethod,
                [name]: value,
            })
        },
        [updatedPaymentMethod, setUpdatedPaymentMethod]
    )

    return (
        <Grid container gap={3} flexDirection={'column'}>
            <TextField
                fullWidth
                value={updatedPaymentMethod.name}
                label="Payment method name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submit(updatedPaymentMethod)}
            >
                Submit
            </Button>
        </Grid>
    )
}

export default React.memo(PaymentMethodForm)
