export const saveSession = (token: string, googleToken = '') => {
    localStorage.setItem('token', token)
    if (googleToken) {
        localStorage.setItem('googleToken', googleToken)
    }
}

export const hasGoogleSession = () => {
    return !!localStorage.getItem('googleToken')
}

export const hasSession = () => {
    return !!localStorage.getItem('token')
}

export const deleteSession = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('googleToken')
}

export const getGoogleToken = () => {
    return localStorage.getItem('googleToken')
}

export const getToken = () => {
    return localStorage.getItem('token')
}
